import React, { useState } from 'react';
import { Form, Button, Row, Col, Card } from 'react-bootstrap';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { PageHeader, FormFeedback } from '../Globals';

const pageTitle = "Proof of Purchase";
const pageDescription = "Bought the book & want the high-quality graphics? Fill out & submit the form below.";

export default function Proof(props) {
  // For default date input
  const today = new Date().toISOString().substring(0, 10);
  const [email, setEmail] = useState("");
  const [imageFile, setImageFile] = useState(undefined);
  const [imageExt, setImageExt] = useState(undefined);
  const [isSubmitted, setIsSubmitted] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handleImageChange = (event) => {
    if(event.target.files.length > 0 && event.target.files[0]) {
      const imageFile = event.target.files[0]
      setImageFile(imageFile);
      setImageExt(imageFile.name.split(".").pop());
      // setImagePreviewLocation(URL.createObjectURL(imageFile));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let publicUrl = "";

    if (imageExt) {
      const storage = getStorage();
      const imageRef = storageRef(storage, "proofImages/"+email+"."+imageExt);

      await uploadBytes(imageRef, imageFile);
      publicUrl = await getDownloadURL(imageRef);
    }

    const newProof = {"date": today, "email": email, "proof": publicUrl};
    props.proofCallback(newProof);

    setIsSubmitted(true);
  };

  return (
    <main>
      <div className="container pt-4">
        <div className="row d-flex justify-content-center">
          <div className="mx-md-1">
            <PageHeader title={pageTitle} description={pageDescription}/>
          </div>
          <div className="d-flex justify-content-center">
            <Card className="col-12 col-lg-8">
              <Card.Body className="p-5">
                {/* <Card.Title as="h2" className="pb-3 mb-4">Submit an Inquiry</Card.Title> */}
                <Form id="unsubscribeForm" onSubmit={handleSubmit}>
                  <Form.Group as={Row} className="mb-1" controlId="email">
                    <Form.Label column>Email</Form.Label>
                    <Col sm={10}>
                      <Form.Control type="input" onChange={handleEmailChange} disabled={isSubmitted} required />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1" controlId="image">
                    <Form.Label column>Upload Proof</Form.Label>
                    <Col>
                      <input type="file" onChange={handleImageChange} required />
                      {/* <Form.Control as="file" onChange="handleImageChange" /> */}
                    </Col>
                  </Form.Group>
                  <div className="d-flex justify-content-end me-4">
                    {/* <Button variant="light" type="reset" className="me-3">
                      Clear
                    </Button> */}
                    <Button variant="warning" type="submit" className="btn-accent" disabled={isSubmitted}>
                      Submit
                    </Button>
                  </div>
                </Form>
                {/* Confirmation message */}
                {props.showConfirm &&
                  <FormFeedback alertType={props.alertType} message={props.showConfirmMessage} showConfirmCallback={props.showConfirmCallback} />
                }
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </main>
  );
}
