import React, { useState } from 'react';
import { Container, Navbar, Nav, Button, Form, Row, Col, Alert } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

export function Navigation() {
  // const scrollToTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: 'smooth', // Use smooth scrolling behavior if available
  //   });
  //   // window.location.reload();
  // };
  const sep = "·";

  return (
    <Navbar expand="md" sticky="top">
      <Container className="d-flex flex-wrap justify-content-center">
        <div className="d-flex justify-content-center col-auto col-md-12">
          <Navbar.Brand href="../" className="d-flex justify-content-center col-2 col-md-4">
            <img id="nav-icon" src="../img/onr_title.PNG" alt="Our Next Reality" className="d-inline-block align-top" />
          </Navbar.Brand>
        </div>
        {/* <Navbar.Brand href="../" className="d-flex justify-content-center col-auto col-md-12">
          <h1 className="text-blue m-1">
            <img id="nav-icon" src="img/ag_logo.png" width="40" height="40" className="d-inline-block align-top" />
            &emsp;Alvin Wang Graylin
          </h1>
        </Navbar.Brand> */}
        <Navbar.Toggle aria-controls="page-links" />

        <Navbar.Collapse id="page-links">
          <Nav className="d-flex justify-content-center align-items-center col-md-12">
            <LinkContainer to="/"><Nav.Link>Home</Nav.Link></LinkContainer><span className="md-appear">&ensp;{sep}&ensp;</span>
            <LinkContainer to="book"><Nav.Link>Book</Nav.Link></LinkContainer><span className="md-appear">&ensp;{sep}&ensp;</span>
            <LinkContainer to="reviews"><Nav.Link>Reviews</Nav.Link></LinkContainer><span className="md-appear">&ensp;{sep}&ensp;</span>
            <LinkContainer to="author"><Nav.Link>Author</Nav.Link></LinkContainer><span className="md-appear">&ensp;{sep}&ensp;</span>
            <LinkContainer to="events"><Nav.Link>Events & Recordings</Nav.Link></LinkContainer><span className="md-appear">&ensp;{sep}&ensp;</span>
            <LinkContainer to="media"><Nav.Link>Press & Media</Nav.Link></LinkContainer><span className="md-appear">&ensp;{sep}&ensp;</span>
            <LinkContainer to="contact"><Nav.Link>Contact</Nav.Link></LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export function Footer() {
  return (
    <>
      <footer className="bg-blue">
        <div className="container-fluid">
          <div className="d-flex justify-content-center">
            <div className="row d-flex-column justify-content-between">
              <p className="col-auto">&copy;2024 A. Graylin. All rights reserved.</p>
              {/* <address className="col-auto">
                <div className="row">
                  <div className="col-auto">
                    <span className="material-icons">mail</span> <a href="mailto:care@roomtobudge.com">care@roomtobudge.com</a>
                  </div>
                </div>
              </address> */}
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export function PageHeader(props) {
  return (
    <div className="col-12 mb-1">
      <header className="d-flex row justify-content-center">
        <h1 className="d-flex justify-content-end col-auto ps-1 pe-4 sep">{props.title}</h1>
        <p className="d-flex justify-content-start align-self-center col-6 col-md-7 col-lg-5 ms-3">{props.description}</p>
      </header>
    </div>
  )
}

export function SocialsVert() {
  return(    
    <div className="container row col-12 text-center">
      <a href="https://www.linkedin.com/in/agraylin/" className="p-2">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="linkedin">
          <title>LinkedIn</title>
          <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/>
        </svg>
        &emsp;agraylin
      </a>
      <a href="https://twitter.com/AGraylin/" className="p-2">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="x">
          <title>X</title>
          <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm297.1 84L257.3 234.6 379.4 396H283.8L209 298.1 123.3 396H75.8l111-126.9L69.7 116h98l67.7 89.5L313.6 116h47.5zM323.3 367.6L153.4 142.9H125.1L296.9 367.6h26.3z"/>
        </svg>
        &emsp;AGraylin
      </a>
    </div>
  );
}

export function Socials(props) {
  return(    
    <div className="container row col-12 text-center d-flex justify-content-center align-items-end">
      <div className="col-12 col-md-auto">
        <h2 className={"me-md-5 " + props.hide}>Social Media</h2>
      </div>
      <div className="col-auto d-flex justify-content-center">
        <a href="https://www.linkedin.com/in/agraylin/" className="p-2 me-4 col-auto">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="linkedin">
            <title>LinkedIn</title>
            <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/>
          </svg>
          &emsp;agraylin
        </a>
        <a href="https://twitter.com/AGraylin/" className="p-2 col-auto">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="x">
            <title>X</title>
            <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm297.1 84L257.3 234.6 379.4 396H283.8L209 298.1 123.3 396H75.8l111-126.9L69.7 116h98l67.7 89.5L313.6 116h47.5zM323.3 367.6L153.4 142.9H125.1L296.9 367.6h26.3z"/>
          </svg>
          &emsp;AGraylin
        </a>
      </div>
    </div>
  );
}

export function Spinner() {
  return (
    <div className='loading'>
      <span className="loader"></span>
      <p>Loading data...</p>
    </div>
  );
}

export function GPT() {
  return (
    <div className="container row col-12 col-md-8 d-flex justify-content-center text-center py-4">
      <h2>Try Our Q&A AI Agent</h2>
      <p className="col-12 col-md-11">If you have any questions about the content of <strong><cite className="subhead">Our Next Reality</cite></strong>, click below to ask our <strong className="subhead">custom GPT agent</strong> directly.</p>
      <Button as="a" href="https://chat.openai.com/g/g-6qzODVcrX-our-next-reality-ai" target="_blank" rel="noopener noreferrer" variant="warning" size="md" className="col-4 col-md-2 btn-accent px-2">
        <p className="subhead p-0 m-0">Our Next Reality AI</p>
        <img src="../img/gpt.PNG" alt="GPT icon" className="img-fluid p-0 m-0"/>
      </Button>
    </div>
  );
}

export function GPTcon() {
  return (    
    <div className="container row col-12 text-center d-flex justify-content-center align-items-start">
      <div className="col-11 col-md-5 me-md-4">
        <h2 className="col-12 col-md-auto text-center">Q&A AI Agent</h2>
        <p className="col-auto text-center text-md-start mb-0">If you have any questions about <strong><cite className="">Our Next Reality</cite></strong> content, click the button to ask our <strong className="">custom GPT agent</strong> directly.</p>
      </div>
      <div className="col-8 col-md-3 d-flex justify-content-center justify-content-md-start pb-4">
        <Button as="a" href="https://chat.openai.com/g/g-6qzODVcrX-our-next-reality-ai" target="_blank" rel="noopener noreferrer" variant="warning" size="sm" className="container row col-6 btn-accent px-2">
        <p className="subhead p-0 m-0">Our Next Reality AI</p>
        <img src="../img/gpt.PNG" alt="GPT icon" className="img-fluid p-0 m-0"/>
        </Button>
      </div>
    </div>
  );
}

export function EmailSignUpBanner(props) {
  const [email, setEmail] = useState("");
  const today = new Date().toISOString().substring(0, 10);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const newEmail = {"date": today, "email": email};
    props.emailCallback(newEmail);

    document.getElementById("emailForm").reset();
    setEmail("");
  };

  return (
    <div className="container pb-3">
      <h2 className="d-flex justify-content-center">Join the Email List</h2>
      <div className="d-flex justify-content-center m-0">
        <p className="subhead text-center">Sign up to get updates on news & events related to <strong><cite>Our Next Reality</cite></strong>.</p>
        {/* <p className="subhead">Sign up and get access to <strong>high-resolution color graphics</strong>* from the book.</p> */}
      </div>

      <div className="container d-flex row justify-content-center p-0 m-0">
        <p className="col-11 col-lg-4 p-0 m-0 md-appear subb"><em>As a bonus, you will get access to the <strong>high-resolution color version</strong> of the charts & graphs from the book with proof of purchase.</em></p>
        {/* <p className="d-flex col-11 col-lg-4 p-0 m-0">*This list will be used to reward book buyers with a copy of the book's graphics upon follow-up.</p> */}
        <div className="col-11 col-lg-5 pt-2 p-0 m-0 row d-flex align-content-center justify-content-center">
          <Form id="emailForm" className="d-flex align-content-center row p-0 m-0" onSubmit={handleSubmit}>
            <Form.Group as={Row} className="mb-1" controlId="email" >
              <Col size="md" sm={11} md={9} className="me-md-5">
                <Form.Control type="input" placeholder="Your Email Address" onChange={handleEmailChange} required />
              </Col>
              <Col size="md" sm={1} md={1} className="p-md-0 d-flex justify-content-center">
                <Button variant="warning" type="submit" className="btn-accent px-4 px-md-auto py-1 py-md-auto">
                  <strong className="subhead">Subscribe</strong> (+ get the graphics)
                </Button>
              </Col>
            </Form.Group>
          </Form>
          <div>
            {/* Confirmation message */}
            {props.showConfirm &&
              <FormFeedback alertType={props.alertType} message={props.showConfirmMessage} showConfirmCallback={props.showConfirmCallback} />
            }
            {/* {props.showConfirm && (
                <FormFeedback alertType="success" message="Thanks for subscribing!" showConfirmCallback={props.showConfirmCallback} />
            )} */}
          </div>
        </div>
        <p className='sm-appear subb text-md-start text-center'><em>As a bonus, you will get access to the <strong>high-resolution color version</strong> of the charts & graphs from the book with proof of purchase.</em></p>
      </div>
    </div>
  );
}

export function FormFeedback(props) {
  // <div className={"alert py-2 mt-1 col-auto "+ props.type} role="alert">
  //     {props.message}
  //   </div>
  // <FormFeedback type="alert-success" message="Thanks for subscribing!" />
  // <Alert variant="success" onClose={closeAlert} dismissible>
  //     {props.message}
  //   </Alert>
  // <Alert variant="success" onClose={closeAlert} className="p-2" dismissible>
  //             <p className="p-0 m-0">Thanks for subscribing!</p>
  //           </Alert>

  // useEffect(() => {
  //   const timeId = setTimeout(() => {
  //     closeAlert();
  //   }, 3000);

  //   return () => {
  //     clearTimeout(timeId)
  //   }
  // }, []);

  // const closeAlert = () => props.showConfirmCallback;
  // setTimeout(() => {
  //   closeAlert();
  // }, 3000);

  // <div className={"alert py-2 mt-2 col-auto "+ props.type} role="alert">
  //     <p className="text-center m-0">{props.message}</p>
  //   </div>

  return (
    <Alert variant={props.alertType} onClose={props.showConfirmCallback} className="col-12 mt-3 mb-0 p 2" dismissible>
      <p className="subb m-0 text-center">{props.message}</p>
    </Alert>
  );
}