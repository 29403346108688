import { Card, Button } from "react-bootstrap";
import { PageHeader } from "../Globals";

const pageTitle = "Reviews";
const pageDescription = <span>Find out what others thought of <em>Our Next Reality</em></span>;

export function ReviewCard(props) {
  return(
    <Card className="col-12 col-md-5 p-3 m-3">
      <div className="d-flex justify-content-center px-md-5">
        {props.image && <img src={props.image} alt={props.reviewer} className="circle"/>}
      </div>
      <Card.Body>
        <blockquote className="blockquote">
          <p className="blockquote">
            "{props.quote}"
          </p>
          <footer className="blockquote-footer pb-0">
            {props.reviewer}, <em>{props.cred}</em>
          </footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export function ReviewGroup(props) {
  // const cards = props.reviews.map((review, i) => <ReviewCard reviewer={review.reviewer} cred={review.cred} image={review.image} quote={review.quote} key={i}/>);
  let cards = props.reviews.sort((a, b) => a.order - b.order);
  cards = cards.map((review, i) => <ReviewCard isFeatured={review.isFeatured} order={review.order} reviewer={review.reviewer} cred={review.cred} image={review.image} quote={review.quote} reviewCallback={props.reviewCallback} id={review.key} key={review.key}/>);

  return(
    <div id="endorsements" className="container-fluid row d-flex justify-content-center px-0 pt-0 mx-0 my-5">
      {props.header && <h1 className="text-center">Reviews</h1>}
      {cards}
    </div>
  );
}

export default function Reviews(props) {
  return (
    <>
      <div className="container pt-4">
        <div className="row d-flex justify-content-center">
          <PageHeader title={pageTitle} description={pageDescription}/>
          <main>
          {/* <div className="col-12 col-lg mx-1"> */}
              {props.isFetching ? '' : <ReviewGroup reviews={props.reviews} />}
          {/* </div> */}
          </main>
        </div>
      </div>
    </>
  );
}
