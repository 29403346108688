import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { PageHeader } from '../Globals';

function EventSorting(props) {
  const today = new Date().toISOString().substring(0, 10);

  // Separate events into two arrays
  let upcomingEvents = props.events.filter(event => event.date > today);
  upcomingEvents = upcomingEvents.sort((a, b) => new Date(a.date) -  new Date(b.date));
  let pastEvents = props.events.filter(event => event.date <= today);
  pastEvents = pastEvents.filter(event => event.postLink !== "");
  pastEvents = pastEvents.sort((a, b) => new Date(b.date) - new Date(a.date));

  const upcomingCards = upcomingEvents.map((event, i) => <EventCard name={event.name} date={event.date} location={event.location} description={event.description} link={event.preLink} isPre="true" key={"up"+i} />);
  const pastCards = pastEvents.map((event, i) => <EventCard name={event.name} date={event.date} location={event.location} description={event.description} link={event.postLink} isPre="" key={"past"+i} />);

  return(
    <>
      <div className="px-md-5 mb-md-5">
        <h2 className="text-center text-md-start">Upcoming Events</h2>
        <div className="col-12 my-auto">
          <div className="row d-flex justify-content-center mx-1">
            {upcomingCards}
          </div>
        </div>
        <h2 id="recordings" className="text-center text-md-start pt-5">Past Events</h2>
        <div className="col-12 my-auto">
          <div className="row d-flex justify-content-center mx-1">
            {pastCards}
          </div>
        </div>
      </div>
    </>
  );
}

export function EventCard(props) {
  return (
    <Card className="col-12 col-md-5 m-1 m-md-3">
      <Card.Body className="row d-flex align-items-center mx-2 mb-3">
        <Card.Title as="h2">{props.name}</Card.Title>
        <Card.Subtitle className="my-2 text-muted">{props.date}&ensp; · &ensp;{props.location}</Card.Subtitle>
        <Card.Text>{props.description}</Card.Text>
        {props.link && <Button as="a" href={props.link} target="_blank" rel="noopener noreferrer" variant="secondary" className="bg-blue">{props.isPre ? "Go to Event" : "Watch Recording"}</Button>}
      </Card.Body>
    </Card>
  );
}

export default function Events(props) {
  const pageTitle = "Events";
  const pageDescription = "See details for upcoming events, and watch the recordings of past events";
  // const pageDescription = "See details for Alvin's upcoming events, and watch the recordings of past events";
  
  return (
    <>
      <div className="container pt-4">
        <div className="row d-flex justify-content-center">
          <PageHeader title={pageTitle} description={pageDescription}/>
          <main>
          {props.isFetching ? '' : <EventSorting events={props.events} />}
          </main>
        </div>
      </div>
    </>
  );
}
