import React, { useState } from 'react';
import { Form, Button, Row, Col, Card } from 'react-bootstrap';
import { PageHeader, FormFeedback } from '../Globals';

const pageTitle = "Unsubscribe";
const pageDescription = "No longer want to be on the mailing list? Fill out & submit the form below.";

export default function Unsubscribe(props) {
  // For default date input
  const today = new Date().toISOString().substring(0, 10);
  const [email, setEmail] = useState("");
  const [reason, setReason] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newUnsub = {"date": today, "email": email, "reason": reason};
    props.unsubCallback(newUnsub);
  };

  return (
    <main>
      <div className="container pt-4">
        <div className="row d-flex justify-content-center">
          <div className="mx-md-1">
            <PageHeader title={pageTitle} description={pageDescription}/>
          </div>
          <div className="d-flex justify-content-center">
            <Card className="col-12 col-lg-8">
              <Card.Body className="p-5">
                {/* <Card.Title as="h2" className="pb-3 mb-4">Submit an Inquiry</Card.Title> */}
                <Form id="unsubscribeForm" onSubmit={handleSubmit}>
                  <Form.Group className="mb-4" controlId="email">
                    <Form.Label column>Email</Form.Label>
                      <Form.Control type="input" onChange={handleEmailChange} disabled={props.isSubmitted} required />
                  </Form.Group>
                  <Form.Group className="mb-4" controlId="reason">
                    <Form.Label>Reason for Unsubscribing (optional)</Form.Label>
                    <Form.Control as="textarea" rows={3} onChange={handleReasonChange} disabled={props.isSubmitted} />
                  </Form.Group>
                  <div className="d-flex justify-content-end me-4">
                    {/* <Button variant="light" type="reset" className="me-3">
                      Clear
                    </Button> */}
                    <Button variant="warning" type="submit" className="btn-accent" disabled={props.isSubmitted}>
                      Submit
                    </Button>
                  </div>
                </Form>
                {/* Confirmation message */}
                {props.showConfirm &&
                  <FormFeedback alertType={props.alertType} message={props.showConfirmMessage} showConfirmCallback={props.showConfirmCallback} />
                }
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </main>
  );
}
