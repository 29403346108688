import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Card, Tabs, Tab, Accordion } from 'react-bootstrap';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { PageHeader, FormFeedback } from '../Globals';

import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML,  convertFromHTML } from 'draft-convert';
import DOMPurify from 'dompurify';
import { update } from 'firebase/database';

const pageTitle = "Manage Content";
const pageDescription = "Edit events/reviews or send newsletters";

// Events
function EventForm(props) {
  // For default date input
  const today = new Date().toISOString().substring(0, 10);
  const [name, setName] = useState("");
  const [date, setDate] = useState(today);
  const [location, setLocation] = useState("");
  const [imageFile, setImageFile] = useState(undefined);
  const [imageExt, setImageExt] = useState(undefined);
  const [description, setDescription] = useState("");
  const [preLink, setPreLink] = useState("");
  const [postLink, setPostLink] = useState("");
  const [isFeatured, setIsFeatured] = useState(false);

  const handleFeaturedChange = (event) => {
    setIsFeatured(event.target.checked);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handleDateChange = (event) => {
    setDate(event.target.value);
  };
  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };
  const handleImageChange = (event) => {
    if(event.target.files.length > 0 && event.target.files[0]) {
      const imageFile = event.target.files[0]
      setImageFile(imageFile);
      setImageExt(imageFile.name.split(".").pop());
      // setImagePreviewLocation(URL.createObjectURL(imageFile));
    }
  };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const handlePreLinkChange = (event) => {
    setPreLink(event.target.value);
  };
  const handlePostLinkChange = (event) => {
    setPostLink(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let  publicUrl = "";

    if (imageExt) {
      const storage = getStorage();
      const imageRef = storageRef(storage, "eventImages/"+name+"."+imageExt);

      await uploadBytes(imageRef, imageFile);
      publicUrl = await getDownloadURL(imageRef);
    }

    const newEvent = {"name": name, "date": date, "location": location, "image": publicUrl, "description": description,
                "preLink": preLink, "postLink": postLink, "isFeatured": isFeatured};
    props.eventCallback(newEvent, "add");

    document.getElementById("eventForm").reset();
    setName("");
    setDate(today);
    setLocation("");
    setImageFile(undefined);
    setImageExt(undefined);
    setDescription("");
    setPreLink("");
    setPostLink("");
    setIsFeatured(false);
  };

  return (
    <Card className="col-12 col-md-6">
      <Card.Body className="p-5">
        <Card.Title as="h2" className="pb-3 mb-4">List an Event</Card.Title>
        <Form id="eventForm" onSubmit={handleSubmit}>
          <Form.Group as={Row} className="mb-1" controlId="featured">
            <Form.Label column>Featured?</Form.Label>
            <Col sm={9}>
            <Form.Check type="switch" onChange={handleFeaturedChange} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="name">
            <Form.Label column>Name*</Form.Label>
            <Col sm={9}>
            <Form.Control type="input" onChange={handleNameChange} required />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="date">
            <Form.Label column>Date*</Form.Label>
            <Col sm={9}>
              <Form.Control type="date" onChange={handleDateChange} defaultValue={today} required />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="location">
            <Form.Label column>Location*</Form.Label>
            <Col sm={9}>
            <Form.Control type="input" onChange={handleLocationChange} required />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="image">
            <Form.Label column>Upload Photo</Form.Label>
            <Col>
              <input type="file" onChange={handleImageChange} />
              {/* <Form.Control as="file" onChange="handleImageChange" /> */}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="description">
            <Form.Label column>Description</Form.Label>
            <Col sm={9}>
              <Form.Control as="textarea" rows={3} onChange={handleDescriptionChange} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="preLink">
            <Form.Label column>Pre-Event Link</Form.Label>
            <Col sm={9}>
              <Form.Control as="input" onChange={handlePreLinkChange} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="postLink">
            <Form.Label column>Post-Event Link</Form.Label>
            <Col sm={9}>
              <Form.Control as="input" onChange={handlePostLinkChange} />
            </Col>
          </Form.Group>
          <div className="d-flex justify-content-end me-4">
            <Button variant="light" type="reset" className="me-3">
              Clear
            </Button>
            <Button variant="warning" type="submit" className="btn-accent">
              Add Event
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
}

function EventEditForm(props) {
  const [name, setName] = useState(props.existingInfo.name);
  const [date, setDate] = useState(props.existingInfo.date);
  const [location, setLocation] = useState(props.existingInfo.location);
  const [imageFile, setImageFile] = useState("");
  const [imageExt, setImageExt] = useState("");
  const [description, setDescription] = useState(props.existingInfo.description);
  const [preLink, setPreLink] = useState(props.existingInfo.preLink);
  const [postLink, setPostLink] = useState(props.existingInfo.postLink);
  const [isFeatured, setIsFeatured] = useState(props.existingInfo.isFeatured);

  const handleFeaturedChange = (event) => {
    setIsFeatured(event.target.checked);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handleDateChange = (event) => {
    setDate(event.target.value);
  };
  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };
  const handleImageChange = (event) => {
    if(event.target.files.length > 0 && event.target.files[0]) {
      const imageFile = event.target.files[0]
      setImageFile(imageFile);
      setImageExt(imageFile.name.split(".").pop());
      // setImagePreviewLocation(URL.createObjectURL(imageFile));
    }
  };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const handlePreLinkChange = (event) => {
    setPreLink(event.target.value);
  };
  const handlePostLinkChange = (event) => {
    setPostLink(event.target.value);
  };

  const handleCancel = () => {
    props.editingCallback();
  };

  const handleDelete = () => {
    props.eventCallback(null, "remove", props.existingInfo.id);
  }

  const handleEdit = async (event) => {
    event.preventDefault();

    let  publicUrl = "";

    if (imageExt) {
      const storage = getStorage();
      const imageRef = storageRef(storage, "eventImages/"+name+"."+imageExt);

      await uploadBytes(imageRef, imageFile);
      publicUrl = await getDownloadURL(imageRef);
    } else if (props.existingInfo.image) {
      publicUrl = props.existingInfo.image;
    }

    const newEvent = {"name": name, "date": date, "location": location, "image": publicUrl, "description": description,
                "preLink": preLink, "postLink": postLink, "isFeatured":isFeatured};
    props.eventCallback(newEvent, "set", props.existingInfo.id);
    props.editingCallback();
  };

  return (
    <Card className="col-12">
      <Card.Body className="p-5">
        <Card.Title as="h2" className="pb-3 mb-4">Edit Event</Card.Title>
        <Form id="eventEditForm" onSubmit={handleEdit}>
          <Form.Group as={Row} className="mb-1" controlId="featured">
            <Form.Label column>Featured?</Form.Label>
            <Col sm={9}>
            <Form.Check type="switch" onChange={handleFeaturedChange} defaultChecked={isFeatured} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="name">
            <Form.Label column>Name*</Form.Label>
            <Col sm={9}>
            <Form.Control type="input" onChange={handleNameChange} defaultValue={name} required />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="date">
            <Form.Label column>Date*</Form.Label>
            <Col sm={9}>
              <Form.Control type="date" onChange={handleDateChange} defaultValue={date} required />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="location">
            <Form.Label column>Location*</Form.Label>
            <Col sm={9}>
            <Form.Control type="input" onChange={handleLocationChange} defaultValue={location} required />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="image">
            <Form.Label column>Upload Photo</Form.Label>
            <Col>
              <input type="file" onChange={handleImageChange} />
              {/* <Form.Control as="file" onChange="handleImageChange" /> */}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="description">
            <Form.Label column>Description</Form.Label>
            <Col sm={9}>
              <Form.Control as="textarea" rows={3} onChange={handleDescriptionChange} defaultValue={description} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="preLink">
            <Form.Label column>Pre-Event Link</Form.Label>
            <Col sm={9}>
              <Form.Control as="input" onChange={handlePreLinkChange} defaultValue={preLink} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="postLink">
            <Form.Label column>Post-Event Link</Form.Label>
            <Col sm={9}>
              <Form.Control as="input" onChange={handlePostLinkChange} defaultValue={postLink} />
            </Col>
          </Form.Group>
          <div className="d-flex justify-content-end me-4">
            <Button variant="light" onClick={handleCancel} className="me-3">
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDelete} className="me-3">
              Delete
            </Button>
            <Button variant="warning" type="submit" className="btn-accent">
              Edit Event
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
}

function EventList(props) {
  const today = new Date().toISOString().substring(0, 10);

  // Separate events into two arrays
  let upcomingEvents = props.events.filter(event => event.date > today);
  upcomingEvents = upcomingEvents.sort((a, b) => new Date(a.date) -  new Date(b.date));
  let pastEvents = props.events.filter(event => event.date <= today);
  pastEvents = pastEvents.sort((a, b) => new Date(b.date) - new Date(a.date));

  const upcomingCards = upcomingEvents.map((event, i) => <EventCard isFeatured={event.isFeatured} name={event.name} date={event.date} location={event.location} description={event.description} preLink={event.preLink} postLink={event.postLink} eventCallback={props.eventCallback} id={event.key} key={event.key} />);
  const pastCards = pastEvents.map((event, i) => <EventCard isFeatured={event.isFeatured} name={event.name} date={event.date} location={event.location} description={event.description} preLink={event.preLink} postLink={event.postLink} eventCallback={props.eventCallback} id={event.key} key={event.key} />);

  return(
      <div className="col-12 col-md-6 ps-md-1">
        <Accordion defaultActiveKey="up">
          <Accordion.Item eventKey="up">
            <Accordion.Header><h2>Upcoming Events ({upcomingEvents.length})</h2></Accordion.Header>
            <Accordion.Body className="col-12 my-auto">
              <div className="row d-flex justify-content-center mx-1">
                {upcomingCards}
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="past">
            <Accordion.Header><h2>Past Events ({pastEvents.length})</h2></Accordion.Header>
            <Accordion.Body className="col-12 my-auto">
              <div className="row d-flex justify-content-center mx-1">
                {pastCards}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
  );
}

function EventCard(props) {
  const [isEditing, setIsEditing] = useState();

  const startEditingState = () => {
    setIsEditing(true);
  };

  const stopEditingState = () => {
    setIsEditing(false);
  };
  
  return (
    <>
    {isEditing ? <EventEditForm existingInfo={props} editingCallback={stopEditingState} eventCallback={props.eventCallback} /> : 
      <Card className="col-12 m-1 m-md-3">
        <Card.Body className="row d-flex align-items-center mb-3">
          <Card.Title as="h2">
            {props.isFeatured && <span><span className="material-icons">star</span>&ensp;</span>}
            {props.name}
          </Card.Title>
          <Card.Subtitle className="my-2 text-muted">{props.date}&ensp; · &ensp;{props.location}</Card.Subtitle>
          <Card.Text>{props.description}</Card.Text>
          <div>
            {props.preLink === "" ? <Button variant="secondary" className="bg-blue" disabled>Pre-Link</Button> :
                                    <Button as="a" href={props.preLink} target="_blank" rel="noopener noreferrer" variant="secondary" className="bg-blue">Pre-Link</Button>
            }
            {props.postLink === "" ? <Button variant="secondary" className="bg-blue" disabled>Post-Link</Button> :
                                    <Button as="a" href={props.postLink} target="_blank" rel="noopener noreferrer" variant="secondary" className="bg-blue">Post-Link</Button>
            }
          </div>
          <Button onClick={startEditingState} variant="warning" className="btn-accent">Edit Details</Button>
        </Card.Body>
      </Card>
    }
    </>
  );
}

// Media
function MediaForm(props) {
  // For default date input
  const today = new Date().toISOString().substring(0, 10);
  const [name, setName] = useState("");
  const [date, setDate] = useState(today);
  const [type, setType] = useState("Article");
  const [program, setProgram] = useState("");
  const [host, setHost] = useState("");
  const [imageFile, setImageFile] = useState(undefined);
  const [imageExt, setImageExt] = useState(undefined);
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");

  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handleDateChange = (event) => {
    setDate(event.target.value);
  };
  const handleTypeChange = (event) => {
    setType(event.target.value);
  };
  const handleProgramChange = (event) => {
    setProgram(event.target.value);
  };
  const handleHostChange = (event) => {
    setHost(event.target.value);
  };
  const handleImageChange = (event) => {
    if(event.target.files.length > 0 && event.target.files[0]) {
      const imageFile = event.target.files[0]
      setImageFile(imageFile);
      setImageExt(imageFile.name.split(".").pop());
      // setImagePreviewLocation(URL.createObjectURL(imageFile));
    }
  };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const handleLinkChange = (event) => {
    setLink(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let  publicUrl = "";

    if (imageExt) {
      const storage = getStorage();
      const imageRef = storageRef(storage, "mediaImages/"+name+"."+imageExt);

      await uploadBytes(imageRef, imageFile);
      publicUrl = await getDownloadURL(imageRef);
    }

    const newMedia = {"name": name, "date": date, "type": type, "program": program, "host": host, "image": publicUrl, 
                "description": description, "link": link};
    props.mediaCallback(newMedia, "add");

    document.getElementById("mediaForm").reset();
    setName("");
    setDate(today);
    setType("Article");
    setProgram("");
    setHost("");
    setImageFile(undefined);
    setImageExt(undefined);
    setDescription("");
    setLink("");
  };

  return (
    <Card className="col-12 col-md-6">
      <Card.Body className="p-5">
        <Card.Title as="h2" className="pb-3 mb-4">List a Media Activity</Card.Title>
        <Form id="mediaForm" onSubmit={handleSubmit}>
          <Form.Group as={Row} className="mb-1" controlId="name">
            <Form.Label column>Title*</Form.Label>
            <Col sm={9}>
            <Form.Control type="input" onChange={handleNameChange} required />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="date">
            <Form.Label column>Date*</Form.Label>
            <Col sm={9}>
              <Form.Control type="date" onChange={handleDateChange} defaultValue={today} required />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="type">
            <Form.Label column>Type of Media*</Form.Label>
            <Col sm={9}>
              <Form.Select onChange={handleTypeChange} required>
                <option value="Article">Article</option>
                <option value="Book Review">Book Review</option>
                <option value="Interview">Interview</option>
                <option value="Podcast">Podcast</option>
                <option value="Speech">Speech</option>
                <option value="Seminar">Seminar</option>
              </Form.Select>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="program">
            <Form.Label column>Program/Platform*</Form.Label>
            <Col sm={9}>
            <Form.Control type="input" onChange={handleProgramChange} required />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="host">
            <Form.Label column>Host/Interviewer</Form.Label>
            <Col sm={9}>
            <Form.Control type="input" onChange={handleHostChange} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="image">
            <Form.Label column>Upload Photo</Form.Label>
            <Col>
              <input type="file" onChange={handleImageChange} />
              {/* <Form.Control as="file" onChange="handleImageChange" /> */}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="description">
            <Form.Label column>Description</Form.Label>
            <Col sm={9}>
              <Form.Control as="textarea" rows={3} onChange={handleDescriptionChange} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="link">
            <Form.Label column>Link</Form.Label>
            <Col sm={9}>
              <Form.Control as="input" onChange={handleLinkChange} />
            </Col>
          </Form.Group>
          <div className="d-flex justify-content-end me-4">
            <Button variant="light" type="reset" className="me-3">
              Clear
            </Button>
            <Button variant="warning" type="submit" className="btn-accent">
              Add Media
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
}

function MediaEditForm(props) {
  const [name, setName] = useState(props.existingInfo.name);
  const [date, setDate] = useState(props.existingInfo.date);
  const [type, setType] = useState(props.existingInfo.type);
  const [program, setProgram] = useState(props.existingInfo.program);
  const [host, setHost] = useState(props.existingInfo.host);
  const [imageFile, setImageFile] = useState(undefined);
  const [imageExt, setImageExt] = useState(undefined);
  const [description, setDescription] = useState(props.existingInfo.description);
  const [link, setLink] = useState(props.existingInfo.link);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handleDateChange = (event) => {
    setDate(event.target.value);
  };
  const handleTypeChange = (event) => {
    setType(event.target.value);
  };
  const handleProgramChange = (event) => {
    setProgram(event.target.value);
  };
  const handleHostChange = (event) => {
    setHost(event.target.value);
  };
  const handleImageChange = (event) => {
    if(event.target.files.length > 0 && event.target.files[0]) {
      const imageFile = event.target.files[0]
      setImageFile(imageFile);
      setImageExt(imageFile.name.split(".").pop());
      // setImagePreviewLocation(URL.createObjectURL(imageFile));
    }
  };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const handleLinkChange = (event) => {
    setLink(event.target.value);
  };

  const handleCancel = () => {
    props.editingCallback();
  };

  const handleDelete = () => {
    props.mediaCallback(null, "remove", props.existingInfo.id);
  };

  const handleEdit = async (event) => {
    event.preventDefault();

    let  publicUrl = "";

    if (imageExt) {
      const storage = getStorage();
      const imageRef = storageRef(storage, "mediaImages/"+name+"."+imageExt);

      await uploadBytes(imageRef, imageFile);
      publicUrl = await getDownloadURL(imageRef);
    } else if (props.existingInfo.image) {
      publicUrl = props.existingInfo.image;
    }

    const newMedia = {"name": name, "date": date, "type": type, "program": program, "host": host, "image": publicUrl, 
    "description": description, "link": link};
    props.mediaCallback(newMedia, "set", props.existingInfo.id);
    props.editingCallback();
  };

  return (
    <Card className="col-12">
      <Card.Body className="p-5">
        <Card.Title as="h2" className="pb-3 mb-4">Edit Media Activity</Card.Title>
        <Form id="mediaEditForm" onSubmit={handleEdit}>
          <Form.Group as={Row} className="mb-1" controlId="name">
            <Form.Label column>Title*</Form.Label>
            <Col sm={9}>
            <Form.Control type="input" onChange={handleNameChange} defaultValue={name} required />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="date">
            <Form.Label column>Date*</Form.Label>
            <Col sm={9}>
              <Form.Control type="date" onChange={handleDateChange} defaultValue={date} required />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="type">
            <Form.Label column>Type of Media*</Form.Label>
            <Col sm={10}>
              <Form.Select onChange={handleTypeChange} defaultValue={type} required>
                <option value="Article">Article</option>
                <option value="Book Review">Book Review</option>
                <option value="Interview">Interview</option>
                <option value="Podcast">Podcast</option>
                <option value="Speech">Speech</option>
                <option value="Seminar">Seminar</option>
              </Form.Select>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="program">
            <Form.Label column>Program/Platform*</Form.Label>
            <Col sm={9}>
            <Form.Control type="input" onChange={handleProgramChange} defaultValue={program} required />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="host">
            <Form.Label column>Host/Interviewer</Form.Label>
            <Col sm={9}>
            <Form.Control type="input" onChange={handleHostChange} defaultValue={host} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="image">
            <Form.Label column>Upload Photo</Form.Label>
            <Col>
              <input type="file" onChange={handleImageChange} />
              {/* <Form.Control as="file" onChange="handleImageChange" /> */}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="description">
            <Form.Label column>Description</Form.Label>
            <Col sm={9}>
              <Form.Control as="textarea" rows={3} onChange={handleDescriptionChange} defaultValue={description} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="link">
            <Form.Label column>Link</Form.Label>
            <Col sm={9}>
              <Form.Control as="input" onChange={handleLinkChange} defaultValue={link} />
            </Col>
          </Form.Group>
          <div className="d-flex justify-content-end me-4">
            <Button variant="light" onClick={handleCancel} className="me-3">
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDelete} className="me-3">
              Delete
            </Button>
            <Button variant="warning" type="submit" className="btn-accent">
              Edit Media
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
}

function MediaList(props) {
  const medias = props.media.sort((a, b) => new Date(b.date) -  new Date(a.date));
  const cards = medias.map(media => <MediaCard name={media.name} date={media.date} type={media.type} program={media.program} host={media.host} description={media.description} image={media.image} link={media.link} mediaCallback={props.mediaCallback} id={media.key} key={media.key} />);

  return(
      <div className="col-12 col-md-6 ps-md-1">
        <Accordion defaultActiveKey="up">
          <Accordion.Item eventKey="up">
            <Accordion.Header><h2>Previous Media ({cards.length})</h2></Accordion.Header>
            <Accordion.Body className="col-12 my-auto">
              <div className="row d-flex justify-content-center mx-1">
                {cards}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
  );
}

function MediaCard(props) {
  const [isEditing, setIsEditing] = useState();

  const startEditingState = () => {
    console.log("props is", props);
    setIsEditing(true);
  };

  const stopEditingState = () => {
    setIsEditing(false);
  };
  
  return (
    <>
    {isEditing ? <MediaEditForm existingInfo={props} editingCallback={stopEditingState} mediaCallback={props.mediaCallback} /> : 
      <Card className="col-12 px-0 m-1 m-md-3">
        <Card.Header  className="text-center pb-0">
          <h2>{props.program}</h2>
          <p className="subhead mb-1">{props.host}</p>
        </Card.Header>
        <Card.Body className="row d-flex align-items-center mx-2 mb-3">
          <Card.Title>{props.name}</Card.Title>
          <Card.Subtitle className="my-2 text-muted">{props.type}&ensp; · &ensp;{props.date}</Card.Subtitle>
          <Card.Text>{props.description}</Card.Text>
          {props.image && <Card.Img className="bg-blue p-1 mb-3" src={props.image} />}
          <div>
            {props.link && <Button as="a" href={props.link} target="_blank" rel="noopener noreferrer" variant="secondary" className="bg-blue">Media Link</Button>}
          </div>
          <Button onClick={startEditingState} variant="warning" className="btn-accent">Edit Details</Button>
        </Card.Body>
      </Card>
    }
    </>
  );
}


// Reviews
function ReviewForm(props) {
  const total = 13;
  const [reviewer, setReviewer] = useState("");
  const [cred, setCred] = useState("");
  const [imageFile, setImageFile] = useState(undefined);
  const [imageExt, setImageExt] = useState(undefined);
  const [quote, setQuote] = useState("");
  const [isFeatured, setIsFeatured] = useState(false);
  const [order, setOrder] = useState(total);

  const handleFeaturedChange = (event) => {
    setIsFeatured(event.target.checked);
  };
  const handleOrderChange = (event) => {
    setOrder(event.target.value);
  }

  const handleReviewerChange = (event) => {
    setReviewer(event.target.value);
  };
  const handleCredChange = (event) => {
    setCred(event.target.value);
  };
  const handleImageChange = (event) => {
    if(event.target.files.length > 0 && event.target.files[0]) {
      const imageFile = event.target.files[0]
      setImageFile(imageFile);
      setImageExt(imageFile.name.split(".").pop());
      // setImagePreviewLocation(URL.createObjectURL(imageFile));
    }
  };
  const handleQuoteChange = (event) => {
    setQuote(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let publicUrl = "";

    if (imageExt) {
      const storage = getStorage();
      const imageRef = storageRef(storage, "reviewerImages/"+reviewer+"."+imageExt);

      await uploadBytes(imageRef, imageFile);
      publicUrl = await getDownloadURL(imageRef);
    }

    const newReview = {"reviewer": reviewer, "cred": cred, "image": publicUrl, "quote": quote, "isFeatured": isFeatured, "order": order};
    props.reviewCallback(newReview, "add");

    document.getElementById("reviewForm").reset();
    setReviewer("");
    setCred("");
    setImageFile(undefined);
    setImageExt(undefined);
    setQuote("");
    setIsFeatured(false);
    setOrder(total);
  };

  return (
    <Card className="col-12 col-md-6">
      <Card.Body className="p-5">
        <Card.Title as="h2" className="pb-3 mb-4">List a Review</Card.Title>
        <Form id="reviewForm" onSubmit={handleSubmit}>
          <Form.Group as={Row} className="mb-1" controlId="featured">
            <Form.Label column>Featured?</Form.Label>
            <Col sm={9}>
            <Form.Check type="switch" onChange={handleFeaturedChange} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="order">
            <Form.Label column>Order</Form.Label>
            <Col sm={9}>
            <Form.Control type="input" onChange={handleOrderChange} defaultValue={order} required />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="reviewer">
            <Form.Label column>Reviewer*</Form.Label>
            <Col sm={9}>
            <Form.Control type="input" onChange={handleReviewerChange} required />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="cred">
            <Form.Label column>Credential*</Form.Label>
            <Col sm={9}>
            <Form.Control type="input" onChange={handleCredChange} required />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="image">
            <Form.Label column>Upload Photo</Form.Label>
            <Col>
              <input type="file" onChange={handleImageChange} />
              {/* <Form.Control as="file" onChange="handleImageChange" /> */}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="quote">
            <Form.Label column>Quote*</Form.Label>
            <Col sm={9}>
              <Form.Control as="textarea" rows={3} onChange={handleQuoteChange} required />
            </Col>
          </Form.Group>
          <div className="d-flex justify-content-end me-4">
            <Button variant="light" type="reset" className="me-3">
              Clear
            </Button>
            <Button variant="warning" type="submit" className="btn-accent">
              Add Review
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
}

function ReviewEditForm(props) {
  const [reviewer, setReviewer] = useState(props.existingInfo.reviewer);
  const [cred, setCred] = useState(props.existingInfo.cred);
  const [imageFile, setImageFile] = useState(undefined);
  const [imageExt, setImageExt] = useState(undefined);
  const [quote, setQuote] = useState(props.existingInfo.quote);
  const [isFeatured, setIsFeatured] = useState(props.existingInfo.isFeatured);
  const [order, setOrder] = useState(props.existingInfo.order);

  console.log("ex", props.existingInfo); //todo

  const handleFeaturedChange = (event) => {
    setIsFeatured(event.target.checked);
  };
  const handleOrderChange = (event) => {
    setOrder(event.target.value);
  }

  const handleReviewerChange = (event) => {
    setReviewer(event.target.value);
  };
  const handleCredChange = (event) => {
    setCred(event.target.value);
  };
  const handleImageChange = (event) => {
    if(event.target.files.length > 0 && event.target.files[0]) {
      const imageFile = event.target.files[0]
      setImageFile(imageFile);
      setImageExt(imageFile.name.split(".").pop());
      // setImagePreviewLocation(URL.createObjectURL(imageFile));
    }
  };
  const handleQuoteChange = (event) => {
    setQuote(event.target.value);
  };

  const handleCancel = () => {
    props.editingCallback();
  };

  const handleDelete = () => {
    props.reviewCallback(null, "remove", props.existingInfo.id);
  }

  const handleEdit = async (event) => {
    event.preventDefault();

    let  publicUrl = "";

    if (imageExt) {
      const storage = getStorage();
      const imageRef = storageRef(storage, "reviewerImages/"+reviewer+"."+imageExt);

      await uploadBytes(imageRef, imageFile);
      publicUrl = await getDownloadURL(imageRef);
    } else if (props.existingInfo.image) {
      publicUrl = props.existingInfo.image;
    }

    const newReview = {"reviewer": reviewer, "cred": cred, "image": publicUrl, "quote": quote, "isFeatured": isFeatured, "order": order};
    props.reviewCallback(newReview, "set", props.existingInfo.id);
    props.editingCallback();
  };

  return (
    <Card className="col-12">
      <Card.Body className="p-5">
        <Card.Title as="h2" className="pb-3 mb-4">Edit Review</Card.Title>
        <Form id="reviewForm" onSubmit={handleEdit}>
          <Form.Group as={Row} className="mb-1" controlId="featured">
            <Form.Label column>Featured?</Form.Label>
            <Col sm={9}>
            <Form.Check type="switch" onChange={handleFeaturedChange} defaultChecked={isFeatured} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="order">
            <Form.Label column>Order</Form.Label>
            <Col sm={3}>
            <Form.Control type="input" onChange={handleOrderChange} defaultValue={order} required />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="reviewer">
            <Form.Label column>Reviewer*</Form.Label>
            <Col sm={9}>
            <Form.Control type="input" onChange={handleReviewerChange} defaultValue={reviewer} required />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="cred">
            <Form.Label column>Credential*</Form.Label>
            <Col sm={9}>
            <Form.Control type="input" onChange={handleCredChange} defaultValue={cred} required />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="image">
            <Form.Label column>Upload Photo</Form.Label>
            <Col>
              <input type="file" onChange={handleImageChange} />
              {/* <Form.Control as="file" onChange="handleImageChange" /> */}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="quote">
            <Form.Label column>Quote*</Form.Label>
            <Col sm={9}>
              <Form.Control as="textarea" rows={3} onChange={handleQuoteChange} defaultValue={quote} required />
            </Col>
          </Form.Group>
          <div className="d-flex justify-content-end me-4">
            <Button variant="light" onClick={handleCancel} className="me-3">
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDelete} className="me-3">
              Delete
            </Button>
            <Button variant="warning" type="submit" className="btn-accent">
              Edit Review
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
}

function ReviewList(props) {
  let cards = props.reviews.sort((a, b) => a.order - b.order);
  cards = cards.map((review, i) => <ReviewCard isFeatured={review.isFeatured} order={review.order} reviewer={review.reviewer} cred={review.cred} image={review.image} quote={review.quote} reviewCallback={props.reviewCallback} id={review.key} key={review.key}/>);

  return(
      <div className="col-12 col-md-6 ps-md-1">
        <Accordion defaultActiveKey="reviews">
          <Accordion.Item eventKey="reviews">
            <Accordion.Header><h2>Reviews ({cards.length})</h2></Accordion.Header>
            <Accordion.Body className="col-12 my-auto">
              <div className="row d-flex justify-content-center mx-1">
                {cards}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
  );
}

function ReviewCard(props) {
  const [isEditing, setIsEditing] = useState();

  const startEditingState = () => {
    setIsEditing(true);
  };

  const stopEditingState = () => {
    setIsEditing(false);
  };
  
  return (
    <>
    {isEditing ? <ReviewEditForm existingInfo={props} editingCallback={stopEditingState} reviewCallback={props.reviewCallback} /> : 
      <Card className="col-12 p-3">
        <div className="d-flex justify-content-end">
          {<p className="pe-4"><strong>{props.order}</strong></p>}
        </div>
        <div className="d-flex justify-content-center px-5 mx-5">
          {props.isFeatured && <span className="material-icons">star</span>}
          {props.image && <img src={props.image} alt={props.reviewer} className="circle px-md-5"/>}
        </div>
        <Card.Body>
          <blockquote className="blockquote">
            <p className="blockquote">
              "{props.quote}"
            </p>
            <footer className="blockquote-footer pb-0">
              {props.reviewer}, <em>{props.cred}</em>
            </footer>
          </blockquote>
          <Button onClick={startEditingState} variant="warning" className="btn-accent col-12">Edit Details</Button>
        </Card.Body>
      </Card>
    }
    </>
  );
}

// Newsletter
function NewsletterForm(props) {
  // let imgs = [];
  // const [htmlMessage, setHtmlMessage] = useState(null);

  // useEffect(() => {
  //   let html = convertToHTML(editorMessage.getCurrentContent());
  //   setHtmlMessage(html);
  // }, [editorMessage]);

    const [recipentType, setRecipientType] = useState("test");
    const [testEmail, setTestEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [editorMessage, setEditorMessage] = useState( () => EditorState.createEmpty());
    const [formConfirm, setFormConfirm] = useState(false);

    const [isMessageFocused, setIsMessageFocused] = useState(false);
    const handleMessageFocus = () => {
      setIsMessageFocused(true);
    };
    const handleMessageBlur = () => {
      setIsMessageFocused(false);
    };

    const handleRecipientTypeChange = (event) => {
      setRecipientType(event.target.value);
      // const recipents = props.emails.map(obj => obj.email);
      // console.log("test", recipents);
    };
    const handleTestEmailChange = (event) => {
      setTestEmail(event.target.value);
    };
    const handleSubjectChange = (event) => {
      setSubject(event.target.value);
    };

    const convertDraftToHTML = (editorMessage) => {
      // Define custom entity conversion function
      const entityToHTML = (entity, originalText) => {
        if (entity.type === 'LINK') {
          return `<a href="${entity.data.url}" target="_blank" rel="noopener noreferrer">${originalText}</a>`;
        }
        if (entity.type === 'IMAGE') {
          const src = entity.data.src;
          // Include img tag with src attribute for images
          return `<img src="${src}" alt="" style="max-width: 100%" />`;
        }
        if (entity.type === 'COLOR') {
          const color = entity.data.color;
          // Include inline style for text color
          return `<span style="color: ${color};">${originalText}</span>`;
        }
        if (entity.type === 'BACKGROUND_COLOR') {
          const backgroundColor = entity.data.backgroundColor;
          // Include inline style for background color
          return `<span style="background-color: ${backgroundColor};">${originalText}</span>`;
        }
        return originalText;
      };

      const blockToHTML = (block) => {
        if (block.type === 'unstyled' && block.data && block.data['text-align'] === 'center') {
          return {
            start: '<div style="text-align: center;">',
            end: '</div>',
          };
        }
        // For other block types or blocks without center alignment, return undefined
        return undefined;
      };
    
      // Convert Draft.js content state to HTML with custom entity conversion
      const html = convertToHTML({
        styleToHTML: {},
        blockToHTML,
        entityToHTML,
      })(editorMessage.getCurrentContent());
      return html;
    };

    const handleSave = () => {
      const htmlMessage = convertDraftToHTML(editorMessage);
      const extendedHtml = htmlMessage + '<br><hr><div style="text-align: center;">You are receiving this email because you signed up for the mailing list.</div><div style="text-align: center;"><u><a href="http://ournextreality.com/unsubscribe" target="_blank" rel="noopener noreferrer">Unsubscribe</a></u> | <u><a href="http://ournextreality.com/contact" target="_blank" rel="noopener noreferrer">Contact</a></u></div><div style="text-align: center;">©2024 A. Graylin. All rights reserved.</div>'

      // const htmlMessage = convertToHTML(editorMessage.getCurrentContent());
      // const htmlMessage = DOMPurify.sanitize(convertToHTML(editorMessage.getCurrentContent()));
      // const plainMessage = editorMessage.getCurrentContent().getPlainText();
      const newLetter = {"bcc": "none", "message": {"subject": subject, "html": extendedHtml}};
      props.newsletterCallback(newLetter, "save");

      setRecipientType("test");
      setTestEmail("");
      setSubject("");
      setEditorMessage(() => EditorState.createEmpty());
    }

    const handleSubmit = (event) => {
      event.preventDefault();
      let recipents = [testEmail];
      if (recipentType === "real" && !props.isFetching) {
        // TODO: allow when finalized 
        recipents = props.emails.map(obj => obj.email);
      }
      const htmlMessage = convertDraftToHTML(editorMessage);
      const extendedHtml = htmlMessage + '<br><hr><div style="text-align: center;"><u><a href="http://ournextreality.com" target="_blank" rel="noopener noreferrer">OurNextReality.com</a></u></div><div style="text-align: center;">You are receiving this email because you signed up for the mailing list.</div><div style="text-align: center;"><u><a href="http://ournextreality.com/unsubscribe" target="_blank" rel="noopener noreferrer">Unsubscribe</a></u> | <u><a href="http://ournextreality.com/contact" target="_blank" rel="noopener noreferrer">Contact</a></u></div><div style="text-align: center;">©2024 A. Graylin. All rights reserved.</div>';
      // const extendedHtml = htmlMessage + '<br><hr><div style="text-align: center;">You are receiving this email because you signed up for the mailing list.</div><div style="text-align: center;"><u><a href="http://ournextreality.com" target="_blank" rel="noopener noreferrer">OurNextReality.com</a></u></div><div style="text-align: center;"><u><a href="http://ournextreality.com/unsubscribe" target="_blank" rel="noopener noreferrer">Unsubscribe</a></u> | <u><a href="http://ournextreality.com/contact" target="_blank" rel="noopener noreferrer">Contact</a></u></div><div style="text-align: center;">©2024 A. Graylin. All rights reserved.</div>';

      // const htmlMessage = convertToHTML(editorMessage.getCurrentContent());
      // const htmlMessage = DOMPurify.sanitize(convertToHTML(editorMessage.getCurrentContent()));
      // const plainMessage = editorMessage.getCurrentContent().getPlainText();
      const newLetter = {"bcc": recipents, "message": {"subject": subject, "html": extendedHtml}};

      props.newsletterCallback(newLetter, "send");
      setFormConfirm(true);

      if (recipentType === "real") {
      document.getElementById("newsletterForm").reset();
      
      setRecipientType("test");
      setTestEmail("");
      setSubject("");
      setEditorMessage(() => EditorState.createEmpty());
      }
    };

    const uploadImageToStorage = async (file) => {
      let publicUrl = "";
  
      const storage = getStorage();
      const imageRef = storageRef(storage, "newsletterImages/"+file.name);

      await uploadBytes(imageRef, file);
      publicUrl = await getDownloadURL(imageRef);
      
      return publicUrl;
    };

    const imgUploadCallback = async (file) => { 
      // let uploadedImages = this.state.uploadedImages;

      // const imageObject = {
      //   file: file,
      //   localSrc: URL.createObjectURL(file)
      // }

      // uploadedImages.push(imageObject);

      // this.setState(uploadedImages, uploadedImages);

      // return new Promise(
      //   (resolve, reject) => {
      //     resolve({ data: { link: imageObject.localSrc } });
      //   }
      // )

      try {
        const imageUrl = await uploadImageToStorage(file);
        console.log('Image URL:', imageUrl);
        return {data: {link: imageUrl}};
      } catch (err) {
        console.error('Error uploading image:', err);
      }
    };

    return (
      <Card className="col-12 col-md-6">
        <Card.Body className="p-5">
          <Card.Title as="h2" className="pb-3 mb-4">Send a Newsletter</Card.Title>
          <Form id="newsletterForm" onSubmit={handleSubmit}>
            <Form.Group as={Row} className="mb-1" controlId="recipents">
              <Form.Label column>Recipents {recipentType==="test" ? "(1)":"("+props.emails.length+")"}</Form.Label>
              <Col sm={10}>
                <Form.Select onChange={handleRecipientTypeChange} required>
                  <option value="test">Test Email Address</option>
                  <option value="real">Subscribers</option>
                </Form.Select>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="testEmail">
              <Form.Label column>Test Email Address</Form.Label>
              <Col sm={10}>
                <Form.Control type="input" onChange={handleTestEmailChange} required={recipentType==="test"} disabled={recipentType!=="test"} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="subject">
              <Form.Label column>Subject</Form.Label>
              <Col sm={10}>
                <Form.Control type="input" onChange={handleSubjectChange} required />
              </Col>
            </Form.Group>
            <Form.Group className="mb-4" controlId="message">
              <Form.Label>Message</Form.Label>
              <div className={`editor-element-container editor-element-container ${isMessageFocused ? 'focused' : ''}`}>
              <Editor  editorState={editorMessage} onEditorStateChange={setEditorMessage}
              onFocus={handleMessageFocus} onBlur={handleMessageBlur}
                editorClassName="editor-input-container" toolbarClassName="editor-toolbar-container"
                toolbar={{
                options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'image', 'history'],
                inline: { inDropdown: false,
                  options: ['bold', 'italic', 'underline', 'monospace'] },
                blockType: {
                  inDropdown: true,
                  options: ['Normal', 'H1', 'H2', 'H3', 'Blockquote', 'Code']
                },
                list: { inDropdown: false },
                textAlign: { inDropdown: true },
                colorPicker: {
                  colors: [
                    'rgb(71, 69, 64)', 'rgb(25, 35, 67)', 'rgb(235, 233, 233)', 'rgb(255, 253, 253)', 'rgb(255, 198, 57)'
                  ]
                },
                link: { inDropdown: false },
                image: {
                  urlEnabled: true,
                  uploadEnabled: true,
                  alignmentEnabled: true,
                  uploadCallback: imgUploadCallback,
                  previewImage: true,
                  inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                  alt: { present: false, mandatory: false },
                  defaultSize: {
                    height: 'auto',
                    width: '100%',
                  },
                },
                history: { inDropdown: false },
              }}
              />
              </div>
            </Form.Group>
            <div className="d-flex justify-content-end me-4">
              <Button variant="light" type="reset" className="me-3">
                Clear
              </Button>
              <Button variant="warning" onClick={handleSave} className="btn-accent me-3">
                Save as Template
              </Button>
              <Button variant="warning" type="submit" className="btn-accent">
                Send Email
              </Button>
            </div>
          </Form>
          {/* Error message */}
          {!editorMessage && (
            <FormFeedback alertType="danger" message="Please type a message." />
          )}
          {formConfirm && (
            <FormFeedback alertType="success" message="Email Sent." />
          )}
        </Card.Body>
      </Card>
    );
}

function NewsletterEditForm(props) {
    const [subject, setSubject] = useState(props.existingInfo.subject);
    const htmlPre = props.existingInfo.html;

    const contentState = convertFromHTML({
      htmlToStyle: (nodeName, node, currentStyle) => {
        if (nodeName === 'span' && node.style.color === 'blue') {
          return currentStyle.add('BLUE');
        } else {
          return currentStyle;
        }
      },
      htmlToEntity: (nodeName, node, createEntity) => {
        if (nodeName === 'a') {
          return createEntity(
            'LINK',
            'MUTABLE',
            {url: node.href}
          )
        }
        if (nodeName === 'img') {
          return createEntity(
            'IMAGE',
            'IMMUTABLE',
            {src: node.src}
          )
        }
      },
      textToEntity: (text, createEntity) => {
        const result = [];
        text.replace(/\@(\w+)/g, (match, name, offset) => {
          const entityKey = createEntity(
            'AT-MENTION',
            'IMMUTABLE',
            {name}
          );
          result.push({
            entity: entityKey,
            offset,
            length: match.length,
            result: match
          });
        });
        return result;
      },
      htmlToBlock: (nodeName, node) => {
        if (nodeName === 'blockquote') {
          return {
            type: 'blockquote',
            data: {}
          };
        }
      }
    })(htmlPre);

    // temp
    // const convertDraftToHTML = (editorMessage) => {
    //   // Define custom entity conversion function
    //   const entityToHTML = (entity, originalText) => {
    //     if (entity.type === 'LINK') {
    //       return `<a href="${entity.data.url}" target="_blank" rel="noopener noreferrer">${originalText}</a>`;
    //     }
    //     if (entity.type === 'IMAGE') {
    //       const src = entity.data.src;
    //       // Include img tag with src attribute for images
    //       return `<img src="${src}" alt="" style="max-width: 100%" />`;
    //     }
    //     if (entity.type === 'COLOR') {
    //       const color = entity.data.color;
    //       // Include inline style for text color
    //       return `<span style="color: ${color};">${originalText}</span>`;
    //     }
    //     if (entity.type === 'BACKGROUND_COLOR') {
    //       const backgroundColor = entity.data.backgroundColor;
    //       // Include inline style for background color
    //       return `<span style="background-color: ${backgroundColor};">${originalText}</span>`;
    //     }
    //     return originalText;
    //   };

    //   const blockToHTML = (block) => {
    //     if (block.type === 'unstyled' && block.data && block.data['text-align'] === 'center') {
    //       return {
    //         start: '<div style="text-align: center;">',
    //         end: '</div>',
    //       };
    //     }
    //     // For other block types or blocks without center alignment, return undefined
    //     return undefined;
    //   };
    
    //   // Convert Draft.js content state to HTML with custom entity conversion
    //   const html = convertToHTML({
    //     styleToHTML: {},
    //     blockToHTML,
    //     entityToHTML,
    //   })(editorMessage.getCurrentContent());
    //   return html;
    // };



    const [editorMessage, setEditorMessage] = useState( () => EditorState.createWithContent(contentState));

    const [isMessageFocused, setIsMessageFocused] = useState(false);
    const handleMessageFocus = () => {
      setIsMessageFocused(true);
    };
    const handleMessageBlur = () => {
      setIsMessageFocused(false);
    };

    const handleSubjectChange = (event) => {
      setSubject(event.target.value);
    };

    const convertDraftToHTML = (editorMessage) => {
      // Define custom entity conversion function
      const entityToHTML = (entity, originalText) => {
        if (entity.type === 'LINK') {
          return `<a href="${entity.data.url}" target="_blank" rel="noopener noreferrer">${originalText}</a>`;
        }
        if (entity.type === 'IMAGE') {
          const src = entity.data.src;
          // Include img tag with src attribute for images
          return `<img src="${src}" alt="" />`;
          // return `<img src="${src}" alt="" style="max-width: 100%" />`;
        }
        if (entity.type === 'COLOR') {
          const color = entity.data.color;
          // Include inline style for text color
          return `<span style="color: ${color};">${originalText}</span>`;
        }
        if (entity.type === 'BACKGROUND_COLOR') {
          const backgroundColor = entity.data.backgroundColor;
          // Include inline style for background color
          return `<span style="background-color: ${backgroundColor};">${originalText}</span>`;
        }
        return originalText;
      };

      const blockToHTML = (block) => {
        if (block.type === 'unstyled' && block.data && block.data['text-align'] === 'center') {
          return {
            start: '<div style="text-align: center;">',
            end: '</div>',
          };
        }
        // For other block types or blocks without center alignment, return undefined
        return undefined;
      };
    
      // Convert Draft.js content state to HTML with custom entity conversion
      const html = convertToHTML({
        styleToHTML: {},
        blockToHTML,
        entityToHTML,
      })(editorMessage.getCurrentContent());
      return html;
    };

    const handleCancel = () => {
      props.editingCallback();
    };
  
    const handleDelete = () => {
      props.newsletterCallback(null, "remove", props.existingInfo.id);
    }
  
    const handleEdit = async (event) => {
      event.preventDefault();
      const htmlMessage = convertDraftToHTML(editorMessage);

      const newLetter = {"message": {"subject": subject, "html": htmlMessage}};
      props.newsletterCallback(newLetter, "set", props.existingInfo.id);
      props.editingCallback();
    };

    // const handleSave = () => {
    //   const htmlMessage = convertDraftToHTML(editorMessage);
    //   const extendedHtml = htmlMessage + '<br><hr><div style="text-align: center;">You are receiving this email because you signed up for the mailing list.</div><div style="text-align: center;"><u><a href="http://ournextreality.com/unsubscribe" target="_blank" rel="noopener noreferrer">Unsubscribe</a></u> | <u><a href="http://ournextreality.com/contact" target="_blank" rel="noopener noreferrer">Contact</a></u></div><div style="text-align: center;">©2024 A. Graylin. All rights reserved.</div>'

    //   // const htmlMessage = convertToHTML(editorMessage.getCurrentContent());
    //   // const htmlMessage = DOMPurify.sanitize(convertToHTML(editorMessage.getCurrentContent()));
    //   // const plainMessage = editorMessage.getCurrentContent().getPlainText();
    //   const newLetter = {"bcc": "none", "message": {"subject": subject, "html": extendedHtml}};
    //   props.mailCallback(newLetter, "save");

    //   setRecipientType("test");
    //   setTestEmail("");
    //   setSubject("");
    //   setEditorMessage(() => EditorState.createEmpty());
    // }

    // const handleSubmit = (event) => {
    //   event.preventDefault();
    //   let recipents = [testEmail];
    //   if (recipentType === "real" && !props.isFetching) {
    //     // TODO: allow when finalized 
    //     // recipents = props.emails.map(obj => obj.email);
    //   }
    //   const htmlMessage = convertDraftToHTML(editorMessage);
    //   const extendedHtml = htmlMessage + '<br><hr><div style="text-align: center;">You are receiving this email because you signed up for the mailing list.</div><div style="text-align: center;"><u><a href="http://ournextreality.com/unsubscribe" target="_blank" rel="noopener noreferrer">Unsubscribe</a></u> | <u><a href="http://ournextreality.com/contact" target="_blank" rel="noopener noreferrer">Contact</a></u></div><div style="text-align: center;">©2024 A. Graylin. All rights reserved.</div>'

    //   // const htmlMessage = convertToHTML(editorMessage.getCurrentContent());
    //   // const htmlMessage = DOMPurify.sanitize(convertToHTML(editorMessage.getCurrentContent()));
    //   // const plainMessage = editorMessage.getCurrentContent().getPlainText();
    //   const newLetter = {"bcc": recipents, "message": {"subject": subject, "html": extendedHtml}};

    //   props.mailCallback(newLetter, "send");
    //   setFormConfirm(true);
      

    //   }
    // };

    const uploadImageToStorage = async (file) => {
      let publicUrl = "";
  
      const storage = getStorage();
      const imageRef = storageRef(storage, "newsletterImages/"+file.name);

      await uploadBytes(imageRef, file);
      publicUrl = await getDownloadURL(imageRef);
      
      return publicUrl;
    };

    const imgUploadCallback = async (file) => { 
      // let uploadedImages = this.state.uploadedImages;

      // const imageObject = {
      //   file: file,
      //   localSrc: URL.createObjectURL(file)
      // }

      // uploadedImages.push(imageObject);

      // this.setState(uploadedImages, uploadedImages);

      // return new Promise(
      //   (resolve, reject) => {
      //     resolve({ data: { link: imageObject.localSrc } });
      //   }
      // )

      try {
        const imageUrl = await uploadImageToStorage(file);
        console.log('Image URL:', imageUrl);
        return {data: {link: imageUrl}};
      } catch (err) {
        console.error('Error uploading image:', err);
      }
    };

    return (
      <Card className="col-12">
        <Card.Body className="p-5">
          <Card.Title as="h2" className="pb-3 mb-4">Edit Newsletter</Card.Title>
          <Form id="newsletterForm" onSubmit={handleEdit}>
            <Form.Group as={Row} className="mb-3" controlId="subject">
              <Form.Label column>Subject</Form.Label>
              <Col sm={10}>
                <Form.Control type="input" onChange={handleSubjectChange} defaultValue={subject} required />
              </Col>
            </Form.Group>
            <Form.Group className="mb-4" controlId="message">
              <Form.Label>Message</Form.Label>
              <div className={`editor-element-container editor-element-container ${isMessageFocused ? 'focused' : ''}`}>
              <Editor  editorState={editorMessage} onEditorStateChange={setEditorMessage}
              onFocus={handleMessageFocus} onBlur={handleMessageBlur}
                editorClassName="editor-input-container" toolbarClassName="editor-toolbar-container"
                toolbar={{
                options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'image', 'history'],
                inline: { inDropdown: false,
                  options: ['bold', 'italic', 'underline', 'monospace'] },
                blockType: {
                  inDropdown: true,
                  options: ['Normal', 'H1', 'H2', 'H3', 'Blockquote', 'Code']
                },
                list: { inDropdown: false },
                textAlign: { inDropdown: true },
                colorPicker: {
                  colors: [
                    'rgb(71, 69, 64)', 'rgb(25, 35, 67)', 'rgb(235, 233, 233)', 'rgb(255, 253, 253)', 'rgb(255, 198, 57)'
                  ]
                },
                link: { inDropdown: false },
                image: {
                  urlEnabled: true,
                  uploadEnabled: true,
                  alignmentEnabled: true,
                  uploadCallback: imgUploadCallback,
                  previewImage: true,
                  inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                  alt: { present: false, mandatory: false },
                  defaultSize: {
                    height: 'auto',
                    width: '100%',
                  },
                },
                history: { inDropdown: false },
              }}
              />
              </div>
            </Form.Group>
            <div className="d-flex justify-content-end me-4">
              <Button variant="light" onClick={handleCancel} className="me-3">
                Cancel
              </Button>
              <Button variant="danger" onClick={handleDelete} className="me-3">
                Delete
              </Button>
              <Button variant="warning" type="submit" className="btn-accent">
                Edit Template
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    );
}

function NewsletterList(props) {
  const cards = Array.from(props.newsletters).reverse().map((newsletter, i) => <NewsletterCard subject={newsletter.message.subject} html={newsletter.message.html} newsletterCallback={props.newsletterCallback} id={newsletter.key} key={newsletter.key}/>);

  return(
      <div className="col-12 col-md-6 ps-md-1">
        <Accordion defaultActiveKey="reviews">
          <Accordion.Item eventKey="reviews">
            <Accordion.Header><h2>Past Newsletters/Templates ({cards.length})</h2></Accordion.Header>
            <Accordion.Body className="col-12 my-auto">
              <div className="row d-flex justify-content-center mx-1">
                {cards}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
  );
}

function NewsletterCard(props) {
  const [isEditing, setIsEditing] = useState();

  const startEditingState = () => {
    setIsEditing(true);
  };

  const stopEditingState = () => {
    setIsEditing(false);
  };

  const templateCallback = () => {
    // useTemplate(props.html);
  };
  
  return (
    <>
    {isEditing ? <NewsletterEditForm existingInfo={props} editingCallback={stopEditingState} newsletterCallback={props.newsletterCallback} /> : 
      <Card className="col-12 p-3">
        <Card.Title as="h2">Subject: {props.subject}</Card.Title>
        <Card.Body>
          <p>Message</p>
          {props.html}
          <div>
          <Button onClick={startEditingState} variant="warning" className="btn-accent col-6">Edit Template</Button>
          <Button onClick={templateCallback} variant="warning" className="btn-accent col-6">Load Template</Button>
          </div>
        </Card.Body>
      </Card>
    }
    </>
  );
}

export default function Data(props) {
  return (
    <main>
      <div className="container pt-4">
        <div className="row d-flex justify-content-center">
          <div className="col-12 my-auto mx-1">
            <PageHeader title={pageTitle} description={pageDescription}/>
          </div>
          <Tabs defaultActiveKey="events" id="editOptions" className="justify-content-center mb-3">
            <Tab eventKey="events" title="Events">
              <div className="row d-flex align-items-start">
                <EventForm isFetching={props.isFetching} eventCallback={props.eventCallback} />
                {props.isFetching ? '' : <EventList events={props.events} eventCallback={props.eventCallback}/>}
              </div>
            </Tab>
            <Tab eventKey="media" title="Press/Media">
              <div className="row d-flex align-items-start">
                <MediaForm isFetching={props.isFetching} mediaCallback={props.mediaCallback} />
                {props.isFetching ? '' : <MediaList media={props.media} mediaCallback={props.mediaCallback}/>}
              </div>
            </Tab>
            <Tab eventKey="reviews" title="Reviews">
              <div className="row d-flex align-items-start">
                <ReviewForm isFetching={props.isFetching} reviewCallback={props.reviewCallback} />
                {props.isFetching ? '' : <ReviewList reviews={props.reviews} reviewCallback={props.reviewCallback}/>}
              </div>
            </Tab>
            <Tab eventKey="newsletters" title="Newsletters">
              <div className="row d-flex align-items-start">
                <NewsletterForm isFetching={props.isFetching} emails={props.emails} newsletterCallback={props.newsletterCallback}/>
                {props.isFetching ? '' : <NewsletterList newsletters={props.newsletters} newsletterCallback={props.newsletterCallback}/>}
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </main>
  );
}
