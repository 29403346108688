import { PageHeader, Socials } from "../Globals";

const pageTitle = "Author";
const pageDescription = "Learn more about Alvin & Louis' background and expertise";
// const pageDescription = "Learn more about Alvin's background and expertise";

function LRAuthor() {
  return (
    <div id="louis" className="row d-flex justify-content-center">
      <div className="col-8 col-md-4 me-md-5">
        <div className="d-flex justify-content-center">
          <img src="/img/rosenbergl.png" alt="Louis Rosenberg" className="col-10"/>
        </div>
        <h2 className="text-center pt-2">Louis Rosenberg</h2>
        {/* social */}
        <div className="container row col-12 text-center d-flex justify-content-center align-items-end">
          <a href="https://www.linkedin.com/in/louis-rosenberg/" className="p-2 me-4 col-auto">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="linkedin">
              <title>LinkedIn</title>
              <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/>
            </svg>
            &emsp;louis-rosenberg
          </a>
        </div>
      </div>
      <div className="col-11 col-lg-6 my-auto mx-1">
        {/* <div className="p-3 p-lg-5 bg-gray"> */}
          <p>Louis Rosenberg, PhD is an early pioneer of virtual and augmented reality and a longtime AI researcher. His work began over thirty years ago in VR labs at Stanford, NASA, and Air Force Research Laboratory where he developed the Virtual Fixtures platform, the first system enabling users to interact with a mixed reality of real and virtual objects. In 1993, he founded the early VR company Immersion Corporation and brought it public on Nasdaq. In 2004 he founded Outland Research, an early developer of augmented reality and spatial media technology acquired by Google. In 2014 he founded Unanimous AI, a company focused on amplifying the intelligence of networked human groups in pursuit of Collective Superintelligence. He received his PhD from Stanford University, was a tenured professor at California State University, and has been awarded over 300 patents for VR, AR, and AI technologies. He is currently CEO of Unanimous AI, the Chief Scientist of the Responsible Metaverse Alliance and writes often for major publications about the dangers of emerging technologies. His previous books include Arrival Mind, UPGRADE, and Monkey Room.</p>
        {/* </div> */}
      </div>
    </div>
  );
}

export default function Author() {
  return (
    <main>
      <div className="container pt-4">
        <div id="alvin" className="row d-flex justify-content-center">
          <PageHeader title={pageTitle} description={pageDescription}/>
          <div className="col-8 col-md-4 me-md-5">
            <img src="/img/agraylin.jpg" alt="Alvin Graylin"/>
            {/* temp */}<h2 className="text-center pt-2">Alvin Wang Graylin</h2>
            <Socials hide="d-none" />
          </div>
          <div className="col-11 col-lg-6 my-auto mx-1">
            {/* <div className="p-3 p-lg-5 bg-gray"> */}
              <p>Alvin Wang Graylin (汪丛青) is a recognized executive, author, investor, and technology pioneer with over three decades of experience driving innovation in AI, XR, and semiconductors. He currently serves as Global VP at HTC, following a distinguished tenure as China President from 2016 to 2023. Graylin currently also holds leadership roles as Chairman of the Virtual World Society, Vice-Chair of the Industry of VR Alliance, and President of the Virtual Reality Venture Capital Alliance. His latest book, Our Next Reality, published by Hachette Book Group, examines how AI and XR convergence will reshape society.</p>
              <p>At HTC, Graylin was instrumental in securing the company’s recognition as China’s top VR brand at the World Conference of VR Industry from 2018-2022. He was also named the most influential figure in China’s VR/AR industry from 2016 to 2022. Graylin’s early VR research under Prof. Tom Furness at the HIT Lab provided a strong base for his career, which has been further enhanced by his expertise in AI and Natural Language Processing (NLP) from the University of Washington and MIT.</p>
              <p>As a serial entrepreneur, Graylin founded four venture-backed startups across sectors including AI-driven conversational search (2005), mobile social networks (2008), AR local search (2010), and big data AI analytics (2000), spanning both China and the U.S. As an active investor, he has funded over 100 startups and played a pivotal role in the establishment of HTC’s ViveX Global VR accelerator and SOSV’s mobile internet investment incubator. His corporate experience also includes senior roles at Intel/IBM (semiconductors) and Trend Micro/WatchGuard Technologies(cybersecurity).</p>
              <p>A frequent keynote speaker at leading international conferences, Graylin is regularly featured in major media outlets, where he provides thought leadership on immersive computing (AR/VR/MR), artificial intelligence (AI), entrepreneurship, venture investing, and the Chinese market.</p>
              <p>He holds an M.S. in Computer Science specializing in AI from MIT, an M.S. in Business from MIT’s Sloan School of Management, and graduated top of his class with a B.S. in Electrical Engineering from the University of Washington, focusing on VR, AI, and CPU architecture. Fluent in English, Mandarin, and Cantonese, Graylin brings a unique perspective shaped by his Eurasian heritage and extensive experience living and working across both China and the U.S. His career seamlessly bridges technical and business roles, offering deep insights into the future of technology and global markets.</p>
            {/* </div> */}
          </div>
        </div>
        <LRAuthor />
      </div>
    </main>
  );
}
