import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { PageHeader } from '../Globals';

function MediaSorting(props) {
  const today = new Date().toISOString().substring(0, 10);
  let media = props.media.filter(event => event.date <= today);
  media = media.sort((a, b) => new Date(b.date) -  new Date(a.date));

  const mediaCards = media.map((media, i) => <MediaCard name={media.name} date={media.date} type={media.type} program={media.program} host={media.host}  description={media.description} image={media.image} link={media.link} key={i} />);

  return(
    <>
      <div className="px-5 mb-5">
        <div className="col-12 my-auto">
          <div className="row d-flex justify-content-center mx-1">
            {mediaCards}
          </div>
        </div>
      </div>
    </>
  );
}

export function MediaCard(props) {
  return (
    <Card className="col-12 col-md-5 px-0 m-1 m-md-3">
      <Card.Header className="text-center pb-0">
        <h2>{props.program}</h2>
        <p className="subhead mb-1">{props.host}</p>
      </Card.Header>
      <Card.Body className="row d-flex align-items-center mx-2 mb-3">
        <Card.Title>{props.name}</Card.Title>
        <Card.Subtitle className="my-2 text-muted">{props.type}&ensp; · &ensp;{props.date}</Card.Subtitle>
        <Card.Text>{props.description}</Card.Text>
        {props.image && <Card.Img className="bg-blue p-1 mb-3" src={props.image} />}
        {props.link && <Button as="a" href={props.link} target="_blank" rel="noopener noreferrer" variant="secondary" className="bg-blue">Open Link</Button>}
      </Card.Body>
    </Card>
  );
}

export default function Media(props) {
  const pageTitle = "Press & Media";
  const pageDescription = <span>See other activity related to <em>Our Next Reality</em></span>;
  
  return (
    <>
      <div className="container pt-4">
        <div className="row d-flex justify-content-center">
          <PageHeader title={pageTitle} description={pageDescription}/>
          <main>
          {props.isFetching ? '' : <MediaSorting media={props.media} />}
          </main>
        </div>
      </div>
    </>
  );
}
