import { Button, Carousel } from 'react-bootstrap';
import { PageHeader } from '../Globals';
import { ReviewGroup } from './Reviews';

function PicCarousel() {
  return (
    <Carousel className="pb-2">
      {/* <Carousel.Item className="py-1 mb-1">
        <div className="col-12 col-md-9 d-flex justify-content-center align-items-center mx-auto my-auto">
          <div className="col-4 pe-lg-5 m-2 m-lg-5">
            <img src="/img/book_graylin.jpg" alt="Graylin holding Our Next Reality hardcover book" />
          </div>
          <div className="col-4 px-1 ps-0">
            <img src="/img/book_rosenberg.JPG" alt="Rosenberg holding Our Next Reality hardcover book" />
          </div>
        </div>
      </Carousel.Item> */}
      <Carousel.Item>
        <div className="col-7 col-md-4 px-1 px-md-3 px-lg-0 pe-0 m-0 my-md-4 mx-auto">
          <img src="/img/authors_book.jpg" alt="Authors with Our Next Reailty book" />
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="col-8 col-md-5 px-1 px-md-3 px-lg-0 pe-0 m-0 my-md-4 mx-auto">
          <img src="/img/book_physical.jpg" alt="Our Next Reality book jacket" />
        </div>
      </Carousel.Item>
      <Carousel.Item className="py-1 mb-1">
        <div className="col-12 col-md-9 d-flex justify-content-center align-items-center mx-auto my-auto">
          <div className="col-3 pe-0 m-0">
            <img src="/img/onr_3d_audio.png" alt="Our Next Reality audiobook" />
          </div>
          <div className="col-4 px-1 ps-0">
            <img src="/img/onr_3d_book.png" alt="Our Next Reality book" />
          </div>
          <div className="col-2 m-0 me-3">
            <img src="/img/onr_3d_ebook.png" alt="Our Next Reality ebook" />
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="col-7 col-md-5 px-1 pe-0 m-0 mx-auto">
          <img src="/img/onr_book_jacket.png" alt="Our Next Reality book jacket" />
        </div>
      </Carousel.Item>
    </Carousel>
  );
}

function OurNextReality(props) {
  return (<div className="col-12 col-lg mx-1">
    <div className="d-flex row justify-content-center align-items-center p-3 p-lg-5 bg-blue">
      <h1 className="text-center bg-blue"><cite>Our Next Reality</cite></h1>
      <PicCarousel />
      {/* <div className="col-9 d-flex justify-content-center align-items-center">
                <div className="col-3 px-1 pe-0 m-0">
                  <img src="/img/onr_3d_audio.png" alt="Our Next Reality audiobook" />
                </div>
                <div className="col-4 px-1 ps-0">
                  <img src="/img/onr_3d_book.png" alt="Our Next Reality book" />
                </div>
                <div className="col-3 m-0 me-3">
                  <img src="/img/onr_3d_ebook.png" alt="Our Next Reality ebook" />
                </div>
              </div> */}

      <div className="col-11 col-md-10 p-md-5 pb-0">
        <p><strong>Over the last 100 years, technology has changed our world. Over the next decade it will transform our reality. The planet is headed for a momentous upheaval, and we are not prepared.</strong></p>
        <p>We are entering a new technological age in which artificial intelligence and immersive media will transform society at all levels, mediating our lives by altering what we see, hear, and experience. Powered by immersive eyewear and driven by interactive AI agents, this new age of computing has the potential to make our world a magical place where the boundaries between the real and the virtual, the human and the artificial, rapidly fade away. If managed well, this could unleash a new age of abundance. If managed poorly, this technological revolution could easily go astray, deeply compromising our privacy, autonomy, agency, and even our humanity.</p>
        <p>In <i>Our Next Reality</i>, two industry veterans provide a data-driven debate on whether the new world we're creating will be a technological utopia or an AI-powered dystopia and gives guidance on how to aim for the best future we can. With a Foreword by renowned author Neal Stephenson and section contributions from industry thought-leaders such as Peter H. Diamandis, Tom Furness, Phillip Rosedale, Tony Parisi, Avi Bar Zeev and Walter Parkes, this book answers over a dozen of the most pressing questions we face as AI/VR/AR accelerates the digitization of our world. Find out why our actions in the next decade could determine the trajectory of our species for countless millennia.</p>
      </div>

      <div className="row m-0 d-flex justify-content-center align-items-center">
        {/* Ebook */}
        <div className="bg-yellow container row col-10 col-md-2 d-flex align-items-center justify-content-center py-2 me-md-4">
          <p className="subhead text-center mb-1">Order eBook</p>

          <div className="d-flex justify-content-center mb-2 px-0 mx-0">
            <Button href="https://www.amazon.com/dp/1399812246/ref=cm_sw_r_as_gl_api_gl_i_CWSY31NM2VZ2AH3GHH04?linkCode=ml1&tag=tag02b-20" target="_blank" rel="noopener noreferrer" variant="light" size="lg" className=" px-md-3 me-1 me-md-2">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <title>Kindle</title>
                <path fill="#FF9900" d="M257.2 162.7c-48.7 1.8-169.5 15.5-169.5 117.5 0 109.5 138.3 114 183.5 43.2 6.5 10.2 35.4 37.5 45.3 46.8l56.8-56S341 288.9 341 261.4V114.3C341 89 316.5 32 228.7 32 140.7 32 94 87 94 136.3l73.5 6.8c16.3-49.5 54.2-49.5 54.2-49.5 40.7-.1 35.5 29.8 35.5 69.1zm0 86.8c0 80-84.2 68-84.2 17.2 0-47.2 50.5-56.7 84.2-57.8v40.6zm136 163.5c-7.7 10-70 67-174.5 67S34.2 408.5 9.7 379c-6.8-7.7 1-11.3 5.5-8.3C88.5 415.2 203 488.5 387.7 401c7.5-3.7 13.3 2 5.5 12zm39.8 2.2c-6.5 15.8-16 26.8-21.2 31-5.5 4.5-9.5 2.7-6.5-3.8s19.3-46.5 12.7-55c-6.5-8.3-37-4.3-48-3.2-10.8 1-13 2-14-.3-2.3-5.7 21.7-15.5 37.5-17.5 15.7-1.8 41-.8 46 5.7 3.7 5.1 0 27.1-6.5 43.1z" />
                <path fill="#000000" d="M257.2 162.7c-48.7 1.8-169.5 15.5-169.5 117.5 0 109.5 138.3 114 183.5 43.2 6.5 10.2 35.4 37.5 45.3 46.8l56.8-56S341 288.9 341 261.4V114.3C341 89 316.5 32 228.7 32 140.7 32 94 87 94 136.3l73.5 6.8c16.3-49.5 54.2-49.5 54.2-49.5 40.7-.1 35.5 29.8 35.5 69.1zm0 86.8c0 80-84.2 68-84.2 17.2 0-47.2 50.5-56.7 84.2-57.8v40.6z" />
              </svg>
            </Button>

            <Button href="https://books.apple.com/us/book/our-next-reality/id6469685058" target="_blank" rel="noopener noreferrer" variant="light" size="lg" className="px-md-3">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                <title>Apple Books</title>
                <path fill="#999999" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z" />
                {/* <path fill="#666666" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/> */}
                {/* <path fill="#A3AAAE" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/> */}
              </svg>
            </Button>
          </div>
        </div>

        {/* Audiobook */}
        <div className="bg-yellow container row col-10 col-md-2 d-flex align-items-center justify-content-center p-2 me-md-4">
          <p className="subhead text-center mb-1">Order Audiobook</p>

          <div className="d-flex justify-content-center mb-2">
            <Button href="https://www.audible.com/pd/Our-Next-Reality-Audiobook/B0CLD7DW5D" target="_blank" rel="noopener noreferrer" variant="light" size="lg" className="px-md-3 me-1 me-md-2">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <title>Audible</title>
                <path fill="#FF9900" d="M257.2 162.7c-48.7 1.8-169.5 15.5-169.5 117.5 0 109.5 138.3 114 183.5 43.2 6.5 10.2 35.4 37.5 45.3 46.8l56.8-56S341 288.9 341 261.4V114.3C341 89 316.5 32 228.7 32 140.7 32 94 87 94 136.3l73.5 6.8c16.3-49.5 54.2-49.5 54.2-49.5 40.7-.1 35.5 29.8 35.5 69.1zm0 86.8c0 80-84.2 68-84.2 17.2 0-47.2 50.5-56.7 84.2-57.8v40.6zm136 163.5c-7.7 10-70 67-174.5 67S34.2 408.5 9.7 379c-6.8-7.7 1-11.3 5.5-8.3C88.5 415.2 203 488.5 387.7 401c7.5-3.7 13.3 2 5.5 12zm39.8 2.2c-6.5 15.8-16 26.8-21.2 31-5.5 4.5-9.5 2.7-6.5-3.8s19.3-46.5 12.7-55c-6.5-8.3-37-4.3-48-3.2-10.8 1-13 2-14-.3-2.3-5.7 21.7-15.5 37.5-17.5 15.7-1.8 41-.8 46 5.7 3.7 5.1 0 27.1-6.5 43.1z" />
                <path fill="#000000" d="M257.2 162.7c-48.7 1.8-169.5 15.5-169.5 117.5 0 109.5 138.3 114 183.5 43.2 6.5 10.2 35.4 37.5 45.3 46.8l56.8-56S341 288.9 341 261.4V114.3C341 89 316.5 32 228.7 32 140.7 32 94 87 94 136.3l73.5 6.8c16.3-49.5 54.2-49.5 54.2-49.5 40.7-.1 35.5 29.8 35.5 69.1zm0 86.8c0 80-84.2 68-84.2 17.2 0-47.2 50.5-56.7 84.2-57.8v40.6z" />
              </svg>
            </Button>

            <Button href="https://books.apple.com/us/audiobook/our-next-reality/id1724319743" target="_blank" rel="noopener noreferrer" variant="light" size="lg" className="px-md-3">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                <title>Apple Audiobooks</title>
                <path fill="#999999" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z" />
                {/* <path fill="#666666" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/> */}
                {/* <path fill="#A3AAAE" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/> */}
              </svg>
            </Button>
          </div>
        </div>

        {/* Hardcover */}
        <div className="bg-yellow container row col-10 col-md-2 d-flex align-items-center justify-content-center p-2">
          <p className="subhead text-center mb-1">Order Hardcover</p>

          <div className="d-flex justify-content-center mb-2">
            <Button href="https://www.amazon.com/dp/1399812246/ref=cm_sw_r_as_gl_api_gl_i_CWSY31NM2VZ2AH3GHH04?linkCode=ml1&tag=tag02b-20" target="_blank" rel="noopener noreferrer" variant="light" size="lg" className=" px-md-3">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <title>Amazon</title>
                <path fill="#FF9900" d="M257.2 162.7c-48.7 1.8-169.5 15.5-169.5 117.5 0 109.5 138.3 114 183.5 43.2 6.5 10.2 35.4 37.5 45.3 46.8l56.8-56S341 288.9 341 261.4V114.3C341 89 316.5 32 228.7 32 140.7 32 94 87 94 136.3l73.5 6.8c16.3-49.5 54.2-49.5 54.2-49.5 40.7-.1 35.5 29.8 35.5 69.1zm0 86.8c0 80-84.2 68-84.2 17.2 0-47.2 50.5-56.7 84.2-57.8v40.6zm136 163.5c-7.7 10-70 67-174.5 67S34.2 408.5 9.7 379c-6.8-7.7 1-11.3 5.5-8.3C88.5 415.2 203 488.5 387.7 401c7.5-3.7 13.3 2 5.5 12zm39.8 2.2c-6.5 15.8-16 26.8-21.2 31-5.5 4.5-9.5 2.7-6.5-3.8s19.3-46.5 12.7-55c-6.5-8.3-37-4.3-48-3.2-10.8 1-13 2-14-.3-2.3-5.7 21.7-15.5 37.5-17.5 15.7-1.8 41-.8 46 5.7 3.7 5.1 0 27.1-6.5 43.1z" />
                <path fill="#000000" d="M257.2 162.7c-48.7 1.8-169.5 15.5-169.5 117.5 0 109.5 138.3 114 183.5 43.2 6.5 10.2 35.4 37.5 45.3 46.8l56.8-56S341 288.9 341 261.4V114.3C341 89 316.5 32 228.7 32 140.7 32 94 87 94 136.3l73.5 6.8c16.3-49.5 54.2-49.5 54.2-49.5 40.7-.1 35.5 29.8 35.5 69.1zm0 86.8c0 80-84.2 68-84.2 17.2 0-47.2 50.5-56.7 84.2-57.8v40.6z" />
              </svg>
            </Button>
          </div>
        </div>
      </div>
    </div>

    <div className="container pt-3 ">
      <h1 className="text-center">Contents by Chapter</h1>
      <div className="d-flex justify-content-center">
        <ol className="list-group list-group-flush">
          <li className="list-group-item"><strong>Foreword.</strong> &ensp;Neal Stephenson</li>
          <li className="list-group-item"><strong>1.</strong> &emsp; Is the Metaverse Really Going to Happen?</li>
          <li className="list-group-item"><strong>2.</strong> &emsp;What Is the Role of AI in Our Immersive Future?</li>
          <li className="list-group-item"><strong>3.</strong> &emsp;Will the Metaverse Be Centralized or Decentralized?</li>
          <li className="list-group-item"><strong>4.</strong> &emsp;The AI-Powered Metaverse: How Will the Ecosystem Unfold?</li>
          <li className="list-group-item"><strong>5.</strong> &emsp;Privacy, Identity and Security: Can Society Manage?</li>
          <li className="list-group-item"><strong>6.</strong> &emsp;Will AI and Immersive Media Redefine Marketing?</li>
          <li className="list-group-item"><strong>7.</strong> &emsp; How Will Tech Advancement Disrupt Art, Culture and Media?</li>
          <li className="list-group-item"><strong>8.</strong> &emsp;What Will Be the Impact on Our Health and Medicine?</li>
          <li className="list-group-item"><strong>9.</strong> &emsp;How Will Our Children Learn and Develop in an AI-powered Future?</li>
          <li className="list-group-item"><strong>10.</strong> &ensp;Will Superintelligence and Spatial Computing Unleash an Age of Abundance?</li>
          <li className="list-group-item"><strong>11.</strong> &ensp; What Will Be the Geopolitical Impact of a Global Metaverse?</li>
          <li className="list-group-item"><strong>12.</strong> &ensp; The Human Condition: Will This Future Make Us Happen?</li>
          <li className="list-group-item"><strong>13.</strong> &ensp; How Can We Realize the Future We Want?</li>
          <li className="list-group-item"><strong>Epilogue.</strong> &ensp;Our Next Reality - Utopia or Dystopia?</li>
        </ol>
      </div>
    </div>

    <div className="container pt-4">
    {props.isFetching ? '' : <ReviewGroup reviews={props.reviews} header={true} />}
    </div>
  </div>
  );
}

export default function Book(props) {
  const pageTitle = "Book";
  // const pageDescription = "Learn more about Our Next Reality, and order your own copy today";
  const pageDescription = <span>Learn more about <cite>Our Next Reality</cite>, and order your own copy today</span>;

  return (
    <main>
      <div className="container-fluid col-12 pt-4">
        <div className="row d-flex justify-content-center">
          <PageHeader title={pageTitle} description={pageDescription} />
          <OurNextReality isFetching={props.isFetching} reviews={props.reviews} />
        </div>
      </div>
    </main>
  );
}
