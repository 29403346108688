import React, { useState } from 'react';
import { Form, Button, Row, Col, Card } from 'react-bootstrap';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { PageHeader, FormFeedback } from '../Globals';

const pageTitle = "Subscriber Exclusive";
const pageDescription = "Click to download the high-quality book graphics.";

export default function Exclusive(props) {
  const storage = getStorage();
  // const fileRef = storageRef(storage, "exclusives/"+"file.pdf");
   // const fileUrl = getDownloadURL(fileRef);
   const fileUrl = "https://firebasestorage.googleapis.com/v0/b/agraylin-site.appspot.com/o/exclusives%2FOur%20Next%20Reality%20-%20Color%20Figures%20and%20Readers%20Tips%20HD%202024.pdf?alt=media&token=dcbcb0e0-dee3-4084-8b77-7852322f61f5";


  return (
    <main>
      <div className="container pt-4">
        <div className="row d-flex justify-content-center">
          <div className="mx-md-1">
            <PageHeader title={pageTitle} description={pageDescription}/>
          </div>

          <div className="d-flex justify-content-center my-0">
          <div className="container row col-12 text-center d-flex justify-content-center align-items-center mb-1">
            <div className="col-11 col-md-5 pe-md-5">
              <h2 className="col-12 col-md-auto text-center">What's included?</h2>
              <ul>
                <li className="text-start">Enlarged, colored version of figures</li>
                <li className="text-start">Tips for readers (policy makers, corporate leaders, AI-immersive industry leaders, students/children, and intelligent agents)</li>
              </ul>
            </div>
            <div className="col-8 col-md-3 d-flex justify-content-center justify-content-md-start pb-4">
              <Button as="a" href={fileUrl} download="Our Next Reality Book Graphics" target="_blank" rel="noopener noreferrer" variant="warning" size="sm" className="container row col-6 btn-accent px-2">
              <p className="subhead p-0 m-0">Download Graphics</p>
              <span className="material-icons">download</span>
              </Button>
            </div>
          </div>
          </div>

          <div className="text-center my-0">
            <p><em>Note: Please do not distribute or post the figures without written permission.</em></p>
          </div>
        </div>
      </div>
    </main>
  );
}
