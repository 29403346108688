import React, { useState } from 'react';
import { Form, Button, Row, Col, Card } from 'react-bootstrap';
import { PageHeader, Socials, GPTcon, FormFeedback } from '../Globals';

const pageTitle = "Contact";
const pageDescription = "Have inquiries or requests? Fill out & submit the form below.";

export default function Contact(props) {
  // For default date input
  const today = new Date().toISOString().substring(0, 10);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };
  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const htmlMessage = message.replace(/\n/g, '<br>');
    const newReq = {"date": today, "name": name, "email": email, "subject": subject, "message": message, "html": htmlMessage};
    props.requestCallback(newReq);

    document.getElementById("contactForm").reset();
    
    setName("");
    setEmail("");
    setSubject("");
    setMessage("");
  };

  return (
    <main>
      <div className="container pt-4">
        <div className="row d-flex justify-content-center">
          <div className="mx-md-1 mb-0">
            <PageHeader title={pageTitle} description={pageDescription}/>
          </div>
          <Socials hide="" />
          <GPTcon />
          <div className="d-flex justify-content-center">
            <Card className="col-12 col-lg-8">
              <Card.Body className="p-5">
                <Card.Title as="h2" className="pb-3 mb-4">Submit an Inquiry</Card.Title>
                <Form id="contactForm" onSubmit={handleSubmit}>
                  <Form.Group as={Row} className="mb-1" controlId="name">
                    <Form.Label column>Name</Form.Label>
                    <Col sm={10}>
                    <Form.Control type="input" onChange={handleNameChange} required />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1" controlId="email">
                    <Form.Label column>Email</Form.Label>
                    <Col sm={10}>
                      <Form.Control type="input" onChange={handleEmailChange} required />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="subject">
                    <Form.Label column>Subject</Form.Label>
                    <Col sm={10}>
                      <Form.Control type="input" onChange={handleSubjectChange} required />
                    </Col>
                  </Form.Group>
                  {/* <Form.Group className="mb-4" controlId="message">
                    <Form.Label>Message</Form.Label>
                    <div className={`editor-element-container editor-element-container ${isMessageFocused ? 'focused' : ''}`}>
                    <Editor  editorState={editorMessage} onEditorStateChange={setEditorMessage}
                    onFocus={handleMessageFocus} onBlur={handleMessageBlur}
                     editorClassName="editor-input-container" toolbarClassName="editor-toolbar-container"
                     toolbar={{
                      options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'image', 'history'],
                      inline: { inDropdown: false,
                        options: ['bold', 'italic', 'underline', 'monospace'] },
                      blockType: {
                        inDropdown: true,
                        options: ['Normal', 'H1', 'H2', 'H3', 'Blockquote', 'Code', 'Fineprint']
                      },
                      list: { inDropdown: false },
                      textAlign: { inDropdown: true },
                      colorPicker: {
                        colors: [
                          'rgb(71, 69, 64)', 'rgb(25, 35, 67)', 'rgb(235, 233, 233)', 'rgb(255, 253, 253)', 'rgb(255, 198, 57)'
                        ]
                      },
                      link: { inDropdown: false },
                      image: {uploadCallback: props.attachmentCallback},
                      history: { inDropdown: false },
                    }}
                    />
                    </div>
                  </Form.Group> */}

                  <Form.Group className="mb-4" controlId="message">
                    <Form.Label>Message</Form.Label>
                    <Form.Control as="textarea" rows={5} onChange={handleMessageChange} required />
                  </Form.Group>

                  <div className="d-flex justify-content-end me-4">
                    <Button variant="light" type="reset" className="me-3">
                      Clear
                    </Button>
                    <Button variant="warning" type="submit" className="btn-accent">
                      Submit
                    </Button>
                  </div>
                </Form>
                {/* Confirmation message */}
                {props.showConfirm && (
                  <FormFeedback alertType="success" message="Thank you for your submission! We will get back to you soon." showConfirmCallback={props.showConfirmCallback} />
                )}
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </main>
  );
}
