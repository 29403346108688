import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'normalize.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './style.css';
import App from './App';

// const firebaseConfig = {
//   apiKey: "AIzaSyAWtq234eF92jbkL9w0CB0pktQvjPY_p8k",
//   authDomain: "agraylin-site.firebaseapp.com",
//   databaseURL: "https://agraylin-site-default-rtdb.firebaseio.com",
//   projectId: "agraylin-site",
//   storageBucket: "agraylin-site.appspot.com",
//   messagingSenderId: "680301294885",
//   appId: "1:680301294885:web:489d15fd686afd6c0356cc"
// };

// initializeApp(firebaseConfig);

// const admin = require('firebase-admin/app');

//before
// const app = initializeApp({
//   // credential: 'KHjnQSRSG8Wq',
//   apiKey: "AIzaSyAWtq234eF92jbkL9w0CB0pktQvjPY_p8k",
//   authDomain: "agraylin-site.firebaseapp.com",
//   databaseURL: "https://agraylin-site-default-rtdb.firebaseio.com",
//   projectId: "agraylin-site",
//   storageBucket: "agraylin-site.appspot.com",
//   messagingSenderId: "680301294885",
//   appId: "1:680301294885:web:489d15fd686afd6c0356cc"
// });

const firebaseConfig = {
  apiKey: "AIzaSyAWtq234eF92jbkL9w0CB0pktQvjPY_p8k",
  authDomain: "agraylin-site.firebaseapp.com",
  databaseURL: "https://agraylin-site-default-rtdb.firebaseio.com",
  projectId: "agraylin-site",
  storageBucket: "agraylin-site.appspot.com",
  messagingSenderId: "680301294885",
  appId: "1:680301294885:web:489d15fd686afd6c0356cc",
  measurementId: "G-ZFMSBRFREC"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


// const functions = require('firebase-functions');
// exports.addedRequest = functions.database.ref('userData/requests')
//     .onWrite((change, context) => {
//         // Get the data that was written
//         const newValue = change.after.val();
//         if (newValue !== undefined) {
          
//         }

//         // Perform custom logic based on the written value
//         console.log('New value written:', newValue);

//         // Return a promise if asynchronous operations are performed
//         return null;
//     });


// const admin = require('firebase-admin');
// const serviceAccount = "KHjnQSRSG8Wq";
// // const serviceAccount = require('./path/to/serviceAccountKey.json');

// admin.initializeApp({
//   credential: serviceAccount,
//   apiKey: "AIzaSyAWtq234eF92jbkL9w0CB0pktQvjPY_p8k",
//   authDomain: "agraylin-site.firebaseapp.com",
//   databaseURL: "https://agraylin-site-default-rtdb.firebaseio.com",
//   projectId: "agraylin-site",
//   storageBucket: "agraylin-site.appspot.com",
//   messagingSenderId: "680301294885",
//   appId: "1:680301294885:web:489d15fd686afd6c0356cc"
//   // databaseURL: 'https://your-project-id.firebaseio.com' // optional
// });



// // The Cloud Functions for Firebase SDK to setup triggers and logging.
// const {onRequest} = require("firebase-functions/v2/https");
// const {onValueCreated} = require("firebase-functions/v2/database");
// const {logger} = require("firebase-functions");

// // The Firebase Admin SDK to access the Firebase Realtime Database.
// const admin = require("firebase-admin");
// admin.initializeApp();


// //don't know where to put it
// const OnWrittenFunctionInstance = onValueWritten(
//   {
//     ref: "/userCreated/*",
//     instance: "my-app-db-2"
//   },
//   (event) => {
//     // …
//   }
// );

// const functions = require('firebase-functions');
// const nodemailer = require('nodemailer');

// // Create a transport object using Gmail credentials
// const transporter = nodemailer.createTransport({
//   service: 'gmail',
//   auth: {
//     user: 'your@gmail.com',
//     pass: 'your_password',
//   },
// });

// // Define the Cloud Function
// exports.sendEmail = functions.database.ref('/userCreated/requests/*}').onCreate((snapshot) => {
//   const message = snapshot.val();
//   const mailOptions = {
//     from: 'your@gmail.com',
//     to: 'recipient@example.com',
//     subject: 'New Message',
//     text: `New message: ${message.text}`,
//   };

//   // Send the email
//   return transporter.sendMail(mailOptions, (error, info) => {
//     if (error) {
//       return console.error('Error sending email:', error);
//     }
//     console.log('Email sent:', info.response);
//   });
// });


// // Listens for new messages added to /messages/:pushId/original and creates an
// // uppercase version of the message to /messages/:pushId/uppercase
// // for all databases in 'us-central1'
// exports.makeuppercase = onValueCreated(
//   "/messages/{pushId}/original",
//   (event) => {
//   // Grab the current value of what was written to the Realtime Database.
//     const original = event.data.val();
//     logger.log("Uppercasing", event.params.pushId, original);
//     const uppercase = original.toUpperCase();
//     // You must return a Promise when performing
//     // asynchronous tasks inside a function, such as
//     // writing to the Firebase Realtime Database.
//     // Setting an "uppercase" sibling in the
//     // Realtime Database returns a Promise.
//     return event.data.ref.parent.child("uppercase").set(uppercase);
//   },
// );




// function ScrollToTop() {
//   const { pathname } = useLocation();

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);

//   return null;
// }

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//     <BrowserRouter>
//       <ScrollToTop />
//       <App />
//     </BrowserRouter>
// );


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <BrowserRouter>
      {/* <ScrollToTop /> */}
      <App />
    </BrowserRouter>
  // </React.StrictMode>
);